import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.png";
import { Link, useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";

interface LoginVerifyProps {
  phone: string;
  signup?: Function;
}

function SignUp({ phone, signup }: LoginVerifyProps): JSX.Element {
  const [username, setUsername] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [passwordCheck, setPasswordCheck] = useState<string | undefined>("");
  const [textAuth, setTextAuth] = useState<string>(null);
  const [errorText, setErrorText] = useState<string>("");
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const navigate = useNavigate();

  const renderErrorSB = (content: string) => (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={content}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  
  if (phone === null || phone === "") { 
    navigate("/authentication/signup/verify");
  }

  const submitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (textAuth === null || textAuth === "") {
      setErrorText("Code cannot be blank");
      openErrorSB();
    } else if (username === null || username === ""
      || password === null || password === ""
      || passwordCheck === null || passwordCheck === "") {
      setErrorText("Username or password cannot be blank");
      openErrorSB();
    } else if (password !== passwordCheck) {
      setErrorText("Passwords do not match");
      openErrorSB();
    } else {
      const res = await signup(username, password, phone, textAuth);
      if (res === 200) {
        navigate("/authentication/verified");
      } else if (res === 403) {
        setErrorText(
          "You have been blocked for too many attempts. Please contact your Althea provider: 1-866-425-8432"
        );
        openErrorSB();
      } else if (res === 400) {
        setErrorText(
          "Username unavailable, please try another."
        );
        openErrorSB();
      } else {
        setErrorText("Incorrect code");
        openErrorSB();
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create an Account
          </MDTypography>
          <MDTypography variant="subtitle2" color="white" mt={1}>
            Enter the number you received from your SMS/text and create your new account details.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={submitHandler}>
            <MDBox display="flex" alignItems="center" mb={2} mt={-2}>
              <MDTypography variant="subtitle2" color="black" mt={1}>
                Code expires in 10 minutes.
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                style={{ width: "100%" }}
                autoFocus={true}
                type="number"
                placeholder={"Enter Authentication code"}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextAuth(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                value={username}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUsername(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                value={password}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Retype Password"
                value={passwordCheck}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordCheck(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                component={Link}
                variant="gradient"
                color="info"
                fullWidth
                disabled={textAuth == null}
                onClick={submitHandler}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {renderErrorSB(errorText)}
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
