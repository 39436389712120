import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { DeviceEntryReturn, HardwareInfo, WifiDevice } from "../types";
import MDBox from "components/MDBox";
import { Card, Grid, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";

interface SetWifiModalProps {
  is_open: boolean;
  device: DeviceEntryReturn;
  toggle: Function;
  submit_operator_action: Function;
}

const SetWifiModal: React.FC<SetWifiModalProps> = (props) => {
  // Hooks for wifi info that changed
  const [update_ssid, setUpdateSsid] = useState<string>("");
  const [update_pass, setUpdatePass] = useState<string>("");
  const [update_channel, setUpdateChannel] = useState<string>("");
  const [enable_radio, setEnableRadio] = useState<any>(null);

  // Placeholder hooks
  const [radio_display, setRadioDisplay] = useState<any>("Choose Radio");
  const [ssid_placeholder, setSsidPlaceholder] = useState<string>(
    "Select a radio to change"
  );
  const [channel_placeholder, setChannelPlaceholder] = useState<string>(
    "Select a radio to change"
  );
  const [en_dis_placeholder, setEnDisPlaceholder] =
    useState<string>("Select a radio");

  const [changes_applied, setChangesApplied] = useState<string>("");

  const [radio, setRadio] = useState<any>("Choose Radio");
  const [dropDownOpen, setDropDown] = useState<any>(null);

  const dropDownToggle = () => {
    setDropDown(!dropDownOpen);
  };

  function radioOptions(hw: HardwareInfo | null) {
    const ret = [];

    if (hw == null) {
      return;
    }
    for (let i = 0; i < hw?.wifi_devices.length; i++) {
      const radio_name = hw.wifi_devices[i].name;
      let radio_name_with_freq;
      const chan = hw.wifi_devices[i].channel;
      if (chan !== null) {
        if (chan > 20) {
          radio_name_with_freq = radio_name + " (5 GHz)";
        } else {
          radio_name_with_freq = radio_name + " (2.4 GHz)";
        }
      }

      ret.push(
        <DropdownItem key={i}>
          <div
            onClick={(e) =>
              setRadioState(
                e.currentTarget.textContent,
                hw.wifi_devices[i],
                radio_name
              )
            }
          >
            {radio_name_with_freq}
          </div>
        </DropdownItem>
      );
    }
    return ret;
  }

  function setRadioState(
    state: string | null,
    wifi_device: WifiDevice,
    radio: string
  ) {
    if (state == null) {
      return;
    }

    setRadio(radio);
    setRadioDisplay(state);
    setSsidPlaceholder(
      wifi_device.ssid ? wifi_device.ssid : "Set ssid if needed"
    );
    setChannelPlaceholder(
      wifi_device.channel
        ? wifi_device.channel.toString()
        : "Set channel if needed (number only)"
    );
    setEnableRadio(wifi_device.enabled);

    if (wifi_device.enabled) {
      setEnDisPlaceholder("Enabled");
    } else {
      setEnDisPlaceholder("Disabled");
    }
  }

  function send_wifi_operator_action() {
    // build return string
    const delim = String.fromCharCode(0x1f);
    let ret: string = "setwifi" + delim;

    if (radio === null) {
      return;
    } else {
      // If in format 'wlan0' map to 'radio0'
      const re = /wlan/gi;
      ret = ret + radio.toString().replace(re, "radio");
    }

    if (update_ssid !== null && update_ssid !== "") {
      if (update_ssid.toString().indexOf(delim) > -1) {
        setChangesApplied(
          "Your SSID has an invalid hex character! Please remove and try again"
        );
        return;
      }
      ret = ret + delim + "ssid:" + update_ssid.toString();
    }

    if (update_pass !== null && update_pass !== "") {
      if (update_pass.indexOf(delim) > -1) {
        setChangesApplied(
          "Your password has an invalid hex character! Please remove and try again"
        );
        return;
      }
      ret = ret + delim + "pass:" + update_pass.toString();
    }

    if (update_channel !== null && update_channel !== "") {
      // validate if its a number
      if (!isNaN(+update_channel)) {
        ret = ret + delim + "chan:" + update_channel.toString();
      }
    }

    if (enable_radio !== null && enable_radio !== "") {
      ret = ret + delim + "en/dis:" + enable_radio.toString();
    }

    setChangesApplied("Applying Changes! You may close this window");
    props.submit_operator_action([props.device.id], ret, false);
  }

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          display="flex"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox p={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Set Wifi Details
              </ModalHeader>
              <MDBox p={5}>
                <MDTypography variant="h6">
                  Make changes to any of the settings below.
                </MDTypography>
                <span>
                  <>Choose Radio:</>
                  <br />
                  <Dropdown isOpen={dropDownOpen} toggle={dropDownToggle}>
                    <DropdownToggle caret>{radio_display}</DropdownToggle>
                    <DropdownMenu>
                      {radioOptions(props.device.last_hardware_info)}
                    </DropdownMenu>
                  </Dropdown>
                </span>
                <br />

                <span>
                  <> Set Radio SSID: </>
                  <InputGroup>
                    <Input
                      value={update_ssid}
                      placeholder={ssid_placeholder}
                      onChange={(e) => setUpdateSsid(e.target.value)}
                    ></Input>
                  </InputGroup>
                </span>
                <br />

                <span>
                  <> Set Radio password: </>
                  <InputGroup>
                    <Input
                      value={update_pass}
                      placeholder="Set password if needed"
                      onChange={(e) => setUpdatePass(e.target.value)}
                    ></Input>
                  </InputGroup>
                </span>
                <br />

                <span>
                  <> Set Radio channel: </>
                  <InputGroup>
                    <Input
                      value={update_channel}
                      placeholder={channel_placeholder}
                      onChange={(e) => setUpdateChannel(e.target.value)}
                    ></Input>
                  </InputGroup>
                </span>
                <br />

                <span>
                  <>
                    Enable or Disable Radio: (Current State:{" "}
                    {en_dis_placeholder})
                  </>
                  <br />
                  <ButtonGroup>
                    <Button
                      color="primary"
                      onClick={() => setEnableRadio(true)}
                      active={enable_radio === true}
                    >
                      Enable
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setEnableRadio(false)}
                      active={enable_radio === false}
                    >
                      Disable
                    </Button>
                  </ButtonGroup>
                </span>
                <br />

                <span>
                  <>{changes_applied}</>
                  <br />
                </span>
              </MDBox>
              <ModalFooter>
                <Button
                  color="secondary"
                  disabled={radio === "Choose Radio"}
                  onClick={() => send_wifi_operator_action()}
                >
                  Submit
                </Button>
                <Button color="secondary" onClick={() => props.toggle()}>
                  Close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SetWifiModal;
