/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.png";

import MDSnackbar from "components/MDSnackbar";
import { useState } from "react";
import { RUN_MODE } from "App";
import { InputAdornment } from "@mui/material";

function validatePhone(phone: string | undefined) {
    // TESTING ONLY
    if (RUN_MODE === "test") {
        return true;
    }
    //Validates the phone number
    const phoneRegex = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/; // Change this regex based on requirement
    if (phone !== undefined) {
        return phoneRegex.test(phone);
    } else {
        return false;
    }
}

interface SignUpPageProps {
    get_code: Function;
}

const Verify: React.FC<SignUpPageProps> = (props) => {
    
    const [phone, setPhone] = useState<string | undefined>("");
    const [errorText, setErrorText] = useState<string>("");
    const [errorSB, setErrorSB] = useState<boolean>(false);
    const closeErrorSB = () => setErrorSB(false);
    const openErrorSB = () => setErrorSB(true);
    const navigate = useNavigate();

    const renderErrorSB = (content: string) => (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={content}
            dateTime=""
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
        />
    );

    const submitHandler = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (validatePhone(phone)) {
            const res = await props.get_code("+1" + phone);
            if (res !== null && res.ok) {
                navigate("/authentication/signup/create");
            } else {
                if (!res) {
                    setErrorText(JSON.stringify(res));
                } else {
                    setErrorText(
                        "You have been blocked for too many attempts. Please contact your Althea provider: 1-866-425-8432"
                    );
                }
                openErrorSB();
            }
        } else {
            setErrorText("Enter a valid phone number");
            openErrorSB();
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                {renderErrorSB(errorText)}
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Create an Account
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter your phone number to verify your account.
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={submitHandler}>
                        
                        <MDInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                            }}
                            type="tel"
                            label="Phone Number"
                            value={phone}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setPhone(e.target.value);
                            }}
                            fullWidth
                        />
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                component={Link}
                                variant="gradient"
                                color="info"
                                fullWidth
                                disabled={phone == null || phone.length == 0}
                                onClick={submitHandler}
                            >
                                Verify Phone
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Already have an account?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/authentication"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Sign In
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout >
    );
}

export default Verify;
