import React, { useEffect, useState } from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  Input,
} from "reactstrap";
import {
  BillingDetails,
  ContactDetails,
  DeviceEntryReturn,
  MailingAddress,
  NetworkEntry,
} from "../types";
import swal from "sweetalert";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import CustomerModal from "./CustomerModal";
import MDButton from "components/MDButton";

function validatePhone(phone: string | undefined) {
  //Validates the phone number
  const phoneRegex =
    /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/; // Change this regex based on requirement
  if (phone && phone !== undefined && phone !== "") {
    // if we have actual input we must test it
    if (phone.startsWith("+")) {
      phone = phone.substring(2);
    }
    console.log(phone);
    return phoneRegex.test(phone);
  } else {
    // Empty or null phone numbers are acceptable
    return true;
  }
}

interface ContactDetailsModalProps {
  device_to_view: DeviceEntryReturn;
  is_open: boolean;
  toggle: Function;
  submit_contacts: Function;
  submit_billing: Function;
  toggle_customer_info_modal: Function;
  set_customer_info_modal: boolean;
  customer_to_edit: any;
  submit_customer: Function;
  our_permissions: any;
  network: NetworkEntry;
}

const ContactDetailsModal: React.FC<ContactDetailsModalProps> = (props) => {
  const [contactDetails, setContactDetails] = useState<ContactDetails>(
    props.customer_to_edit.contact_details
  );
  const [billingDetails, setBillingDetails] = useState<BillingDetails>(
    props.device_to_view.billing_details || {
      user_first_name: "",
      user_last_name: "",
      mailing_address: {
        city: "",
        country: "",
        postal_code: "",
        state: "",
        street: "",
      },
    }
  );
  const [acp, setAcp] = useState<boolean>(props.device_to_view.acp || false);

  const setMailingAddress = (addr: MailingAddress) => {
    setBillingDetails({ ...billingDetails, mailing_address: addr });
  };
  const addr = billingDetails.mailing_address;
  const customer_account_id = props.customer_to_edit.account_id

  useEffect(() => {
    setContactDetails(props.customer_to_edit.contact_details);
    setBillingDetails(
      props.device_to_view.billing_details || {
        user_first_name: "",
        user_last_name: "",
        mailing_address: {
          city: "",
          country: "",
          postal_code: "",
          state: "",
          street: "",
        },
      }
    );
  }, [props.device_to_view]);
  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll" }}
          display="flow"
        >
          <MDBox px={2} py={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Contact Details
              </ModalHeader>
              <ModalBody>
                <CustomerModal
                  is_open={props.set_customer_info_modal}
                  toggle={props.toggle_customer_info_modal}
                  customer_to_edit={props.customer_to_edit}
                  submit_customer={props.submit_customer}
                  device_to_edit={props.device_to_view}
                  our_permissions={props.our_permissions}
                  network={props.network}
                />
                This reflects the user's contact details set on their router
                settings page
                <hr />
                <i>
                  This checkbox will mark the user for automatically topping up
                  as part of the acp program. Note users must have accurate
                  phone number and email for ACP enrollment
                </i>
                <hr></hr>
                ACP:
                {
                  <input
                    type="checkbox"
                    checked={acp}
                    style={{ marginLeft: 10 }}
                    onChange={() => {
                      setAcp(!acp);
                    }}
                  />
                }
                <hr></hr>
                <Label>
                  <b>Phone:</b>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    id="phoneNumber"
                    value={contactDetails.phone || ""}
                    placeholder="Phone number"
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        phone: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
                <Label>
                  <b>Email:</b>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    id="email"
                    value={contactDetails.email || ""}
                    placeholder="Email address"
                    onChange={(e) => {
                      setContactDetails({
                        ...contactDetails,
                        email: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
                <Label>
                  <b>Customer ID:</b>
                </Label>
                <InputGroup>
                  <Input type="text" value={customer_account_id} readOnly />
                  <Button
                    color="primary"
                    onClick={async () => {
                      props.toggle_customer_info_modal();
                    }}
                  >
                    Edit
                  </Button>
                </InputGroup>
                <hr />
                <Label>
                  <b>Name:</b>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    id="firstname"
                    value={billingDetails.user_first_name}
                    placeholder={"First Name"}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        user_first_name: e.target.value,
                      })
                    }
                  />
                  <Input
                    type="text"
                    id="lastname"
                    value={billingDetails.user_last_name}
                    placeholder={"Last Name"}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        user_last_name: e.target.value,
                      })
                    }
                  />
                </InputGroup>
                <Label>
                  <b>Service Address:</b>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    id="street"
                    value={addr.street}
                    placeholder={"Street"}
                    onChange={(e) =>
                      setMailingAddress({ ...addr, street: e.target.value })
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    type="text"
                    id="city"
                    value={addr.city}
                    placeholder={"City"}
                    onChange={(e) =>
                      setMailingAddress({ ...addr, city: e.target.value })
                    }
                  />
                  <Input
                    type="text"
                    id="state"
                    value={addr.state || ""}
                    placeholder={"State (if applicable)"}
                    onChange={(e) =>
                      setMailingAddress({ ...addr, state: e.target.value })
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    type="text"
                    id="postalcode"
                    value={addr.postal_code}
                    placeholder={"Postal Code"}
                    onChange={(e) =>
                      setMailingAddress({
                        ...addr,
                        postal_code: e.target.value,
                      })
                    }
                  />
                  <Input
                    type="text"
                    id="country"
                    value={addr.country}
                    placeholder={"Country"}
                    onChange={(e) =>
                      setMailingAddress({ ...addr, country: e.target.value })
                    }
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <MDButton
                  color="primary"
                  onClick={async () => {
                    if (!validatePhone(contactDetails.phone)) {
                      swal({
                        title: "Error",
                        text: "Invalid phone number",
                        icon: "warning",
                      });
                      return;
                    } else {
                      const res = await props.submit_contacts(
                        contactDetails.phone,
                        contactDetails.email,
                        props.customer_to_edit.account_id,
                        acp
                      );
                      if (res.ok) {
                        const res2 = await props.submit_billing(
                          props.device_to_view.id,
                          billingDetails.user_first_name,
                          billingDetails.user_last_name,
                          addr.country,
                          addr.postal_code,
                          addr.state,
                          addr.city,
                          addr.street
                        );
                        if (res2.ok) {
                          props.toggle();
                        } else {
                          const value = await res2.json();
                          const text = `Failed to save billing details: ${value}`;
                          swal({
                            title: "Error",
                            text: text,
                            icon: "warning",
                          });
                        }
                      } else {
                        const value = await res.json();
                        const text = `Failed to save contact info or acp: ${value}`;
                        swal({
                          title: "Error",
                          text: text,
                          icon: "warning",
                        });
                      }
                    }
                  }}
                >
                  Save
                </MDButton>{" "}
                <MDButton color="secondary" onClick={() => props.toggle()}>
                  close
                </MDButton>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ContactDetailsModal;
