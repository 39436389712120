/* eslint-disable prefer-const */
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { get_bandwidth_usage } from "./LatenciesModal";
import { NetworkEntry, UsageTracker, UserActivityHistory } from "types";
import MDBadgeDot from "components/MDBadgeDot";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { CardBody, ModalBody } from "reactstrap";
import MDButton from "components/MDButton";
import AverageUserCard from "examples/Cards/InfoCards/AverageUserCard";
import EarningsCard from "examples/Cards/InfoCards/EarningsCard";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { wei_to_eth } from "utils";

function get_device_counts(network: NetworkEntry) {
  const devices = new Map();
  let labels = [];
  let data = [];

  for (const device of network.devices) {
    if (device.last_hardware_info) {
      const model = device.last_hardware_info.model.replace("_", "-");
      if (devices.has(model)) {
        const count = devices.get(model);
        devices.set(model, count + 1);
      } else {
        devices.set(model, 1);
      }
    }
  }
  for (const [key, count] of devices) {
    labels.push(key);
    data.push(count);
  }
  const res: Types = {
    labels: labels,
    datasets: {
      label: "devices",
      backgroundColors: ["info", "dark", "error", "secondary", "primary"],
      data: data,
    },
  };
  return res;
}

// get the finance data for the operator address and returns a react-chartjs-2 graphing
// format
function get_operator_finances(operator_finance_data: any) {
  const operator_finances = operator_finance_data.operator_address_finances;
  const now = operator_finance_data.date;
  now.setDate(now.getDate() - operator_finances.length);
  let labels = [];
  let income_data = [];
  const finances_copy = [...operator_finances];
  for (const value of finances_copy.reverse()) {
    labels.push(now.getDate() + "-" + now.getMonth() + "-" + now.getFullYear());
    income_data.push(wei_to_eth(value.income));
    now.setDate(now.getDate() + 1);
  }
  const res: Types = {
    labels: labels,
    datasets: [
      {
        label: "Income",
        color: "info",
        data: income_data,
      },
    ],
  };
  return res;
}

// get the finance data for the the whole network and returns a reacht-chartsjs-2 graphing
// format for each of the User Deposits and User Spending and Relay Earning Summary graphs.
function get_network_finances(operator_finance_data: any) {
  const network_finances = operator_finance_data.total_user_finances;
  const now = operator_finance_data.date;
  now.setDate(now.getDate() - network_finances.length);
  let labels = [];
  let income = [];
  let withdraw = [];
  let spending = [];
  let deposits = [];
  // we need to shallow copy to use reverse
  const network_copy = [...network_finances];
  for (const value of network_copy.reverse()) {
    labels.push(now.getDate() + "-" + now.getMonth() + "-" + now.getFullYear());
    income.push(wei_to_eth(value.income));
    withdraw.push(wei_to_eth(value.withdraws));
    spending.push(wei_to_eth(value.spending));
    deposits.push(wei_to_eth(value.deposits));
    now.setDate(now.getDate() + 1);
  }
  const deposits_only: Types = {
    labels: labels,
    datasets: [
      {
        label: "Deposits",
        color: "info",
        data: deposits,
      },
    ],
  };
  const spending_earning: Types = {
    labels: labels,
    datasets: [
      {
        label: "Income",
        color: "info",
        data: income,
      },
      {
        label: "Spending",
        color: "info",
        data: spending,
      },
    ],
  };
  return [deposits_only, spending_earning];
}

// get the finance data for the the whole network and returns a reacht-chartsjs-2 graphing
// format for each of the User Deposits and User Spending and Relay Earning Summary graphs.
function get_network_activity_history(activity_history: any) {
  let timestamps = [];
  let user_count = [];
  // we need to shallow copy to use reverse
  for (const value of activity_history) {
    const d = new Date(value.time * 3600000);
    let dstring = d.toISOString();
    dstring = dstring.substring(0, dstring.length - 8);
    timestamps.push(dstring);
    user_count.push(value.count);
  }
  const chart_data: Types = {
    labels: timestamps,
    datasets: [
      {
        label: "Online Users",
        color: "info",
        data: user_count,
      },
    ],
  };
  return chart_data;
}

// types
interface Types {
  labels: any;
  datasets: any;
}

export function create_usage_charts(usage_data: UsageTracker) {
  const [a, b] = get_bandwidth_usage(usage_data);
  const usage_client = a;
  const usage_relay = b;

  if (!a || !b || a.length == 0 || b.length == 0) {
    return null;
  }

  const client_data: Types = {
    labels: usage_client[0].Timestamps,
    datasets: [
      {
        label: "Upload Mbps",
        color: "info",
        data: usage_client[0].UpUsage,
      },
      {
        label: "Download Mbps",
        color: "dark",
        data: usage_client[0].DownUsage,
      },
    ],
  };
  const relay_data: Types = {
    labels: usage_relay[0].Timestamps,
    datasets: [
      {
        label: "Upload Mbps",
        color: "info",
        data: usage_relay[0].UpUsage,
      },
      {
        label: "Download Mbps",
        color: "dark",
        data: usage_relay[0].DownUsage,
      },
    ],
  };

  const client = (
    <MDBox width="100%">
      <DefaultLineChart
        description={
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              Client Bandwidth Usage
            </MDTypography>
            <MDBadgeDot color="info" size="md" badgeContent="Upload Mbps" />
            <MDBadgeDot color="dark" size="md" badgeContent="Download Mbps" />
          </MDBox>
        }
        chart={client_data}
      />
    </MDBox>
  );
  const relay = (
    <MDBox width="100%">
      <DefaultLineChart
        description={
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              Relay Bandwidth Usage
            </MDTypography>
            <MDBadgeDot color="info" size="md" badgeContent="Upload Mbps" />
            <MDBadgeDot color="dark" size="md" badgeContent="Download Mbps" />
          </MDBox>
        }
        chart={relay_data}
      />
    </MDBox>
  );
  return [client, relay];
}

function get_bandwidth_card(usage_data: UsageTracker) {
  if (
    !usage_data ||
    usage_data.client_bandwidth.length == 0 ||
    usage_data.relay_bandwidth.length == 0
  ) {
    return (
      <CardBody>
        <MDBox m={3}>
          <MDTypography>Found no usage data for this network.</MDTypography>
        </MDBox>
      </CardBody>
    );
  } else {
    const [client, relay] = create_usage_charts(usage_data);
    return (
      <MDBox p={2} width="50%">
        <CardBody>
          <MDBox mt={3} ml={4}>
            <MDTypography variant="h5">
              Total Bandwidth Usage in this Network Over Last 12 Hours
            </MDTypography>
          </MDBox>
          <MDBox p={2}>{client}</MDBox>
          <MDBox p={2}>{relay}</MDBox>
        </CardBody>
      </MDBox>
    );
  }
}

function get_finances_card(operator_finances: any) {
  if (!operator_finances) {
    return (
      <MDBox p={2}>
        <CardBody>
          <MDTypography>Found no finance data for this network.</MDTypography>
        </CardBody>
      </MDBox>
    );
  } else {
    const network_finances_data = get_network_finances(operator_finances);
    return (
      <>
        <MDBox p={2} width="50%">
          <CardBody>
            <GradientLineChart
              title="Operator Fee Earnings"
              description="Measured in DAI / day. Spikes represent users with empty routers depositing and catching up"
              chart={get_operator_finances(operator_finances)}
              height="100%"
            />
          </CardBody>
          <CardBody>
            <GradientLineChart
              title="User Spending and Relay Earning Summary"
              description="This graphs total user spending and total relay earnings (including gateways) in DAI / day. 
                            The difference between these two graphs is the operator fee + transaction fees"
              chart={network_finances_data[1]}
              height="100%"
            />
          </CardBody>
        </MDBox>
        <MDBox p={2} width="50%">
          <CardBody>
            <GradientLineChart
              title="User Deposits"
              description="Daily deposit activity, Wyre, Coinbase and sending users funds yourself all show up here. Denominated in DAI"
              chart={network_finances_data[0]}
              height="100%"
            />
          </CardBody>
        </MDBox>
        <MDBox p={2}>
          <CardBody>
            <AverageUserCard
              users={operator_finances.users}
              relays={operator_finances.relays}
              average_spending={wei_to_eth(
                operator_finances.average_spending
              ).toFixed(2)}
              median_spending={wei_to_eth(
                operator_finances.median_spending
              ).toFixed(2)}
              average_deposit={wei_to_eth(
                operator_finances.average_deposit
              ).toFixed(2)}
              median_deposit={wei_to_eth(
                operator_finances.median_deposit
              ).toFixed(2)}
              average_relay_spending={wei_to_eth(
                operator_finances.average_relay_spending
              ).toFixed(2)}
              median_relay_spending={wei_to_eth(
                operator_finances.median_relay_spending
              ).toFixed(2)}
            />
          </CardBody>
        </MDBox>
        <MDBox p={2}>
          <CardBody>
            <EarningsCard
              total_bandwidth_income={wei_to_eth(
                operator_finances.total_bandwidth_income
              ).toFixed(2)}
              total_operator_income={wei_to_eth(
                operator_finances.total_operator_income
              ).toFixed(2)}
            />
          </CardBody>
        </MDBox>
      </>
    );
  }
}

function get_active_user_history_card(activity_history: UserActivityHistory) {
  if (!activity_history) {
    return (
      <MDBox p={2} width="50%">
        <CardBody>
          {" "}
          <MDTypography>
            Found no user history data for this network.
          </MDTypography>
        </CardBody>
      </MDBox>
    );
  } else {
    const user_count_data = get_network_activity_history(activity_history);
    return (
      <>
        <MDBox p={2} width="50%">
          <CardBody>
            <GradientLineChart
              title="Online User Count History"
              description="This data is averaged and may not be 100% accurate over long history periods"
              chart={user_count_data}
              height="100%"
            />
          </CardBody>
        </MDBox>
      </>
    );
  }
}

function get_devices_card(network: NetworkEntry) {
  if (!network || !network.devices || network.devices.length == 0) {
    return (
      <MDBox p={2}>
        <CardBody>
          <MDTypography>Found no device data for this network.</MDTypography>
        </CardBody>
      </MDBox>
    );
  } else {
    return (
      <MDBox p={2}>
        <CardBody>
          <DefaultDoughnutChart chart={get_device_counts(network)} />
        </CardBody>
      </MDBox>
    );
  }
}
interface OverviewProps {
  network: NetworkEntry;
  usage_data: UsageTracker;
  is_open: boolean;
  toggle: Function;
  operator_finances: any;
  activity_history: UserActivityHistory;
}

const OverviewModal: React.FC<OverviewProps> = (props) => {
  const bandwidth = get_bandwidth_card(props.usage_data);
  const user_count = get_active_user_history_card(props.activity_history);
  const finances = get_finances_card(props.operator_finances);
  const devices = get_devices_card(props.network);

  return (
    <Modal open={props.is_open} onClose={() => props.toggle()}>
      <ModalBody>
        <Card>
          <MDBox pt={3} justifyContent="center" alignSelf="center">
            <MDTypography variant="h4" fontWeight="medium">
              Network Overview
            </MDTypography>
          </MDBox>
          <MDBox alignItems="center" justifyContent="center">
            <Grid container justifyContent="center" display="flex">
              {bandwidth}
              {finances}
              {user_count}
              {devices}
            </Grid>
            <MDBox
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <MDButton color="error" onClick={() => props.toggle()}>
                close
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default OverviewModal;
