import React from "react";
import { Button, ModalBody, ModalFooter, Badge } from "reactstrap";
import { Status } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface RouterStatusesModalProp {
  status_map: Status[];
  flagged_status: any[];
  is_open: boolean;
  toggle: Function;
  name: string;
}
const RouterStatusesModal: React.FC<RouterStatusesModalProp> = (props) => {
  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox px={2} py={10} textAlign="center" justifyContent="center">
            <Card>
              <MDBox p={1}>
                <MDTypography variant="body1">{props.name}</MDTypography>
              </MDBox>
              <ModalBody>
                {format_statuses(props.flagged_status, props.status_map)}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

function format_statuses(
  statuses: Array<[string, string | null]>,
  status_map: Status[]
) {
  // Checks to see if we need to append a the second string to the default status message and creates number array
  // for all the status messages we need to display
  const status_flagged: number[] = [];
  const new_status_array: Array<[string, string]> = [];
  let matched_index = 0;

  for (let index = 0; index < statuses.length; index++) {
    for (
      let status_index = index;
      status_index < status_map.length;
      status_index++
    ) {
      if (statuses[index][0] === status_map[status_index].status) {
        if (statuses[index][1] != null) {
          new_status_array[matched_index] = [
            JSON.parse(JSON.stringify(statuses[index][0])) +
              JSON.parse(JSON.stringify(statuses[index][1])),
            status_map[status_index].color,
          ];
        } else {
          new_status_array[matched_index] = [
            statuses[index][0],
            status_map[status_index].color,
          ];
        }
        status_flagged[matched_index] = matched_index;
        matched_index++;
        break;
      }
    }
  }
  return (
    <div style={{ marginLeft: 5 }}>
      <p>
        Router statuses:
        {status_flagged.map((value: number) => (
          <Badge
            style={{
              backgroundColor: check_router_status_color(
                new_status_array[value][1]
              ),
              marginLeft: 5,
            }}
          >
            {new_status_array[value][0]}
          </Badge>
        ))}
      </p>
    </div>
  );
}
function check_router_status_color(color: string) {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  if (reg.test(color)) {
    return color;
  }
  return undefined;
}

export default RouterStatusesModal;
