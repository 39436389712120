import React from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  Label,
} from "reactstrap";
import { Duration, HardwareInfo, WifiDevice } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

interface WifiModalProp {
  is_open: boolean;
  toggle: Function;
  hw: HardwareInfo | null;
}
const WifiModal: React.FC<WifiModalProp> = (props) => {
  if (!props.hw) {
    return (
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            display="flex"
            style={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <MDBox p={5} textAlign="center" justifyContent="center">
              <Card>
                <ModalBody>{"Cannot display empty hardware"}</ModalBody>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    );
  }
  const uptime_string = JSON.stringify(props.hw.system_uptime);
  const wifi_data = get_wifi_data(props.hw.wifi_devices);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          display="flex"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox p={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                {props.hw.model}
              </ModalHeader>
              <ModalBody>
                <p>
                  <Label>
                    <b>Router uptime: </b>
                    <i>{format_duration_string(uptime_string)}</i>
                  </Label>
                </p>
                {wifi_data}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

function format_duration_string(duration: string) {
  const uptime_parsed: Duration = JSON.parse(duration);
  const seconds = uptime_parsed.secs;
  const month = Math.floor(seconds / (3600 * 24) / 30);
  const d = Math.floor((seconds / (3600 * 24)) % 30);
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const monthDisplay =
    month > 0 ? month + (month === 1 ? " month, " : "months, ") : "";
  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return monthDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
}

function get_wifi_data(devices: Array<WifiDevice>) {
  if (devices === null) {
    return;
  }
  const obj = [];
  const sur_obj = [];
  const stat_obj = [];
  for (let i = 0; i < devices.length; i++) {
    const name = devices[i].name;

    let prev_survey_noise, prev_survey_channel, prev_survey_busy_time;
    for (let j = 0; j < devices[i].survey_data.length; j++) {
      if (
        typeof prev_survey_noise !== "undefined" &&
        prev_survey_noise === devices[i].survey_data[j].noise_dbm &&
        prev_survey_channel ===
          (devices[i].survey_data[j].channel_active_time / 1000).toFixed(1) &&
        prev_survey_busy_time ===
          (devices[i].survey_data[j].channel_busy_time / 1000).toFixed(1)
      )
        continue;
      sur_obj.push(
        <div key={"" + i + "_" + j}>
          <div>
            <Label>
              <b>
                {readable_wifi(devices[i].survey_data[j].frequency_mhz) +
                  " GHz"}{" "}
                wifi device/router with name: ({devices[i].name})
              </b>
            </Label>
          </div>
          <div>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"Noise: " + devices[i].survey_data[j].noise_dbm + " dBm"}
              />
              <Input
                id={name}
                readOnly
                value={
                  "Active Time:" +
                  (
                    devices[i].survey_data[j].channel_active_time / 1000
                  ).toFixed(1) +
                  " seconds"
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Busy Time:" +
                  (devices[i].survey_data[j].channel_busy_time / 1000).toFixed(
                    1
                  ) +
                  " seconds"
                }
              />
            </InputGroup>
            <br />
          </div>
        </div>
      );
      prev_survey_noise = devices[i].survey_data[j].noise_dbm;
      prev_survey_channel = (
        devices[i].survey_data[j].channel_active_time / 1000
      ).toFixed(1);
      prev_survey_busy_time = (
        devices[i].survey_data[j].channel_busy_time / 1000
      ).toFixed(1);
    }
    for (let j = 0; j < devices[i].station_data.length; j++) {
      stat_obj.push(
        <div key={"" + i + "_" + j}>
          <InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"MAC address: " + devices[i].station_data[j].station}
              />
              <Input
                id={name}
                readOnly
                value={
                  "Device last checked in: " +
                  (devices[i].station_data[j].inactive_time_ms / 1000).toFixed(
                    1
                  ) +
                  "seconds"
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Amount uploaded: " +
                  prettyBytes(devices[i].station_data[j].rx_bytes)
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Amount downloaded: " +
                  prettyBytes(devices[i].station_data[j].tx_bytes)
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Upload speed: " +
                  prettyBytes(devices[i].station_data[j].rx_bitrate)
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Download speed: " +
                  prettyBytes(devices[i].station_data[j].tx_bitrate)
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Upload packet retries: " +
                  devices[i].station_data[j].tx_retries
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Download packet retries: " +
                  devices[i].station_data[j].tx_failed
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"Signal: " + devices[i].station_data[j].signal_dbm}
              />
              <Input
                id={name}
                readOnly
                value={
                  "Signal Avg: " + devices[i].station_data[j].signal_avg_dbm
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Connected Time: " +
                  devices[i].station_data[j].connected_time_sec +
                  " sec"
                }
              />
            </InputGroup>
            <Label>
              <b>Current time(UTC):</b>{" "}
              <i>
                {readable_unix_timestamp(
                  devices[i].station_data[j].current_time_ms
                )}
              </i>
            </Label>
          </InputGroup>
          <br />
        </div>
      );
    }
  }
  obj.push(
    <div key={0}>
      <Label>
        <b>All radios:</b>
      </Label>
      <div>{sur_obj}</div>
      <Label>
        <b>All devices:</b>
      </Label>
      <div>{stat_obj}</div>
    </div>
  );
  if (sur_obj.length === 0 && stat_obj.length === 0) {
    return (
      <div>
        <Label>No data to display</Label>
      </div>
    );
  }
  return obj;
}
function prettyBytes(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.min(
    parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
    sizes.length - 1
  );
  return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${sizes[i]}`;
}
function readable_wifi(frequency: number) {
  const wifi_freq = Math.round((frequency / 1000) * 10) / 10;
  return Math.floor(wifi_freq) % 5 === 0 ? 5 : wifi_freq.toFixed(1);
}
function readable_unix_timestamp(epoch_time: number) {
  return new Date(epoch_time * 1000).toLocaleTimeString("en-US");
}

export default WifiModal;
