import React, { useEffect } from "react";
import {
  InputGroup,
  ModalBody,
  Input,
  ModalHeader,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useState } from "react";
import { ContactDetails, Identity, CustomerEntryReturn } from "../types";
import MDButton from "components/MDButton";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultCell from "examples/Tables/DataTable/DefaultCell";
import DataTable from "examples/Tables/DataTable";
import { SERVER_URL } from "App";

interface CustomerModalProps {
  is_open: boolean;
  toggle: Function;
  submit_contacts: Function;
  customer_to_edit: any;
  edit_customer: Function;
  get_customer_list: Function;
}

export const CustomerModal: React.FC<CustomerModalProps> = (props) => {
  if (!props.customer_to_edit) {
    return (
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            justifyContent="center"
            display="flex"
            style={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <MDBox p={5} textAlign="center" justifyContent="center">
              <Card>
                <ModalBody>{"Could not load customer information"}</ModalBody>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    );
  }
  // allowed fields to edit on the customer entry from this modal
  const [customerNickname, setCustomerNickname] = useState<string | null>(null);
  let local_name: string;
  if (
    props.customer_to_edit &&
    props.customer_to_edit.nickname !== null &&
    customerNickname === null
  ) {
    local_name = props.customer_to_edit.nickname;
  } else if (customerNickname !== null) {
    local_name = customerNickname;
  } else {
    local_name = "";
  }
  const [customerNotes, setCustomerNotes] = useState<string | null>(null);
  let local_notes: string;
  if (
    props.customer_to_edit &&
    props.customer_to_edit.notes !== null &&
    customerNotes === null
  ) {
    local_notes = props.customer_to_edit.notes;
  } else if (customerNotes !== null) {
    local_notes = customerNotes;
  } else {
    local_notes = "";
  }
  const [contactDetails, setContactDetails] = useState<ContactDetails>(
    props.customer_to_edit.contact_details
  );

  // fields that stay unchanged
  const account_id = props.customer_to_edit.account_id;
  const attached_devices = props.customer_to_edit.attached_devices;

  useEffect(() => {
    setContactDetails(props.customer_to_edit.contact_details);
    setCustomerNickname(props.customer_to_edit.nickname);
    setCustomerNotes(props.customer_to_edit.notes);
  }, [props.customer_to_edit]);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          justifyContent="center"
          display="flex"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox p={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Customer Information
              </ModalHeader>
              <ModalBody>
                <p>
                  <Label>
                    <b>Current Customer </b>
                  </Label>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">ID</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      readOnly
                      id="account_id"
                      value={account_id}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Nickname</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      value={local_name}
                      placeholder={local_name}
                      onChange={(e) => setCustomerNickname(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Phone</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      id="phoneNumber"
                      value={contactDetails.phone || ""}
                      placeholder="Phone number"
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          phone: e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Email</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      id="email"
                      value={contactDetails.email || ""}
                      placeholder="Email address"
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          email: e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Notes</MDTypography>
                    </MDBox>
                    <Input
                      type="textarea"
                      value={local_notes}
                      placeholder={local_notes}
                      onChange={(e) => setCustomerNotes(e.target.value)}
                    />
                  </InputGroup>
                  <p />
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">
                        Owned Devices
                      </MDTypography>
                    </MDBox>
                    <Input
                      type="textarea"
                      readOnly
                      id="owned_devices"
                      value={attached_devices
                        .map((device: Identity) => device.wg_public_key)
                        .join(", ")}
                    />
                  </InputGroup>
                  <p />
                  <MDButton
                    color="info"
                    onClick={async () => {
                      await props.submit_contacts(
                        contactDetails.phone,
                        contactDetails.email,
                        account_id,
                        null
                      );
                      await props.edit_customer(
                        account_id,
                        local_name,
                        local_notes
                      );
                    }}
                  >
                    Save
                  </MDButton>
                </p>
              </ModalBody>
              <ModalFooter>
                <MDButton
                  color="secondary"
                  onClick={() => {
                    props.toggle();
                  }}
                >
                  Close
                </MDButton>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

interface CustomersProps {
  customers_list: CustomerEntryReturn[];
  toggle_customer_modal: Function;
  customer_modal_is_open: boolean;
  edit_customer: Function;
  submit_contacts: Function;
  get_customer_list: Function;
}

// maps each customer into a row on the table
function generate_row(
  customer: CustomerEntryReturn,
  toggle_customer_modal: Function,
  set_customer_to_edit: Function
) {
  let attached_devices_string = "";
  for (let i = 0; i < customer.attached_devices.length; i++) {
    if (i < customer.attached_devices.length - 1) {
      const name = customer.attached_devices[i].wg_public_key;
      attached_devices_string += name + ", ";
    } else {
      const name = customer.attached_devices[i].wg_public_key;

      attached_devices_string += name;
    }
  }
  const nickname = customer.nickname === null ? "" : customer.nickname;
  const email =
    customer.contact_details.email === null
      ? ""
      : customer.contact_details.email;
  const phone =
    customer.contact_details.phone === null
      ? ""
      : customer.contact_details.phone;
  const notes = customer.notes === null ? "" : customer.notes;
  const button = (
    <MDButton
      color="secondary"
      onClick={() => {
        set_customer_to_edit(customer);
        toggle_customer_modal(customer);
      }}
    >
      Edit
    </MDButton>
  );
  return {
    edit: button,
    id: customer.account_id,
    nickname: nickname,
    email: email,
    phone: phone,
    notes: notes,
    devices: attached_devices_string,
  };
}

const Customers: React.FC<CustomersProps> = (props) => {
  const [customerToEdit, setCustomerToEdit] =
    useState<CustomerEntryReturn>(null);
  const [rows, setRows] = useState<any[]>(
    props.customers_list.map((customer: any) =>
      generate_row(customer, props.toggle_customer_modal, setCustomerToEdit)
    )
  );
  const [initialized, setInitialized] = useState<boolean>(false);

  const customerTableData = {
    columns: [
      {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer ID",
        accessor: "id",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Nickname",
        accessor: "nickname",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone",
        accessor: "phone",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Notes",
        accessor: "notes",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "Devices",
        accessor: "devices",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
    ],
    rows: rows,
  };
  useEffect(() => {
    setRows(
      props.customers_list.map((customer: any) =>
        generate_row(customer, props.toggle_customer_modal, setCustomerToEdit)
      )
    );
  }, [props.customers_list]);

  if (!props.customers_list || props.customers_list.length === 0) {
    if (!initialized) {
      props.get_customer_list();
      setInitialized(true);
      console.log("Getting customers list");
    }
    console.log("Already called get customer list, waiting...");
    return (
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">Customer Management</MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDButton
            onClick={() => window.open(SERVER_URL + "customercsv/")}
            color="info"
          >
            Download CSV
          </MDButton>
        </MDBox>
        <Spinner color="primary" />
      </Card>
    );
  }
  return (
    <div>
      <CustomerModal
        is_open={props.customer_modal_is_open}
        toggle={props.toggle_customer_modal}
        submit_contacts={props.submit_contacts}
        customer_to_edit={customerToEdit}
        edit_customer={props.edit_customer}
        get_customer_list={props.get_customer_list}
      />
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">Customer Management</MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDButton
            onClick={() => window.open(SERVER_URL + "customercsv/")}
            color="info"
          >
            Download CSV
          </MDButton>
        </MDBox>
        <DataTable table={customerTableData} entriesPerPage={false} canSearch />
      </Card>
      <hr />
    </div>
  );
};

export default Customers;
