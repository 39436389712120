import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter, Container } from "reactstrap";
import { DeviceEntryReturn } from "../types";
import {
  OperatorActionProps,
  SuperUserOperatorActionProps,
  SecondaryActionProps,
  we_are_superuser,
  we_are_read_write_user,
} from "../utils";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface MultipleRoutersModalProps {
  devices_to_view: DeviceEntryReturn[];
  address: string;
  is_open: boolean;
  our_permissions: any;
  set_wifi_actions_modal: boolean;
  toggle: Function;
  submit_operator_action: Function;
  submit_device_network: Function;
  operator_action_buttons: Function;
  secondary_action_buttons: Function;
  submit_secondary_action: Function;
  super_user_action_buttons: Function;
  toggle_set_wifi_actions: Function;
  error: any;
  success: any;
}

const MultipleRoutersModal: React.FC<MultipleRoutersModalProps> = (props) => {
  const [newOperatorAddress, setNewOperatorAddress] =
    useState<string>("0xNewAddressHere");
  const [update_auth_keys, setUpdateSshKeys] =
    useState<string>("ssh-public-key");
  const [newTextNotif, setNewTexNotif] = useState<string>("");

  // reload these values when the device list changes so we don't load the modal with previously entered values
  useEffect(() => {
    setNewOperatorAddress("0xNewAddressHere");
    setUpdateSshKeys("ssh-public-key");
  }, [props.devices_to_view]);

  if (!props.devices_to_view || props.devices_to_view.length === 0) {
    return <></>;
  }

  const names = [];
  for (let i = 0; i < props.devices_to_view.length; i++) {
    if (props.devices_to_view[i].name) {
      names.push(props.devices_to_view[i].name);
    } else {
      names.push(props.devices_to_view[i].id.wg_public_key);
    }
  }

  const second_action: SecondaryActionProps = {
    device_to_view: props.devices_to_view,
    our_permissions: props.our_permissions,
    new_text: newTextNotif,
    set_new_text: setNewTexNotif,
    submit_secondary_action: props.submit_secondary_action,
  };

  const sup_action: SuperUserOperatorActionProps = {
    device_to_view: props.devices_to_view,
    our_permissions: props.our_permissions,
    submit_operator_action: props.submit_operator_action,
    set_update_auth_keys: setUpdateSshKeys,
    update_auth_keys: update_auth_keys,
  };

  let superuserButtons = <></>;
  if (props.our_permissions && we_are_superuser(props.our_permissions)) {
    superuserButtons = (
      <>
        <hr />
        <Container>
          {props.super_user_action_buttons(sup_action, true)}
        </Container>
        <Container>
          {props.secondary_action_buttons(second_action, true)}
        </Container>
      </>
    );
  }
  let secondaryButtons = <></>;
  if (
    props.our_permissions &&
    we_are_read_write_user(props.our_permissions, props.address)
  ) {
    secondaryButtons = (
      <Container>
        {props.secondary_action_buttons(second_action, true)}
      </Container>
    );
  }

  const newprops: OperatorActionProps = {
    devices_to_view: props.devices_to_view,
    our_permissions: props.our_permissions,
    submit_operator_action: props.submit_operator_action,
    set_wifi_actions_toggle: props.toggle_set_wifi_actions,
    new_operator_address: newOperatorAddress,
    set_new_operator_address: setNewOperatorAddress,
    submit_device_network: props.submit_device_network,
  };

  return (
    <>
      {props.error}
      {props.success}
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            style={{ overflowX: "scroll" }}
            display="flow"
          >
            <MDBox px={2} py={5} textAlign="center" justifyContent="center">
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="body1">
                    Router Details: {names.toString()}
                  </MDTypography>
                </MDBox>
                <ModalBody>
                  <Container>
                    {props.operator_action_buttons(newprops, true)}
                  </Container>
                  {superuserButtons}
                  {secondaryButtons}
                  <hr />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => props.toggle()}>
                    Close
                  </Button>
                </ModalFooter>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default MultipleRoutersModal;
