/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, ReactNode } from "react";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultDoughnutChart configurations
import configs from "examples/Charts/DoughnutCharts/DefaultDoughnutChart/configs";

// Declaring props types for DefaultDoughnutChart
interface Props {
  icon?: {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColors: string[];
      data: number[];
    };
    cutout?: number;
  };
  [key: string]: any;
}

function DefaultDoughnutChart({ height, chart }: Props): JSX.Element {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

  const renderChart = (
    <MDBox py={2} pr={2} pl={2}>
      <MDBox display="flex" >
        <MDBox>
          <MDTypography variant="h5">Devices</MDTypography>
          <MDBox mb={2}>
            <MDTypography component="div" variant="button" color="text">
              Routers in your network by model
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {useMemo(
        () => (
          <MDBox height={height}>
            <Doughnut data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return <Card>{renderChart}</Card>;
}

// Declaring default props DefaultDoughnutChart
DefaultDoughnutChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

export default DefaultDoughnutChart;
