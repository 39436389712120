// this page is displayed within the networks view, so one might expect it to focus on permissions for the network
// you are currently viewing, this is not the case as it is intended to be a general purpose users viewer and the link
// is what should be moved not the page changed
import React from "react";
import {
  Spinner,
  Table,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Alert,
} from "reactstrap";
import { useState } from "react";
import { we_can_change_users, we_are_superuser } from "../utils";
import { NetworkEntry } from "../types";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";

interface UsersProps {
  users_list: any;
  last_password_change_failed: boolean;
  networks_list: Array<NetworkEntry>;
  our_permissions: Array<any>;
  add_permission: Function;
  add_user: Function;
  remove_permission: Function;
  change_password: Function;
  reset_password: Function;
}

// maps out permissions into multiple rows, one for each
// network
function generate_row(
  user: any,
  our_permissions: Array<any>,
  remove_permission: Function,
  networks_list: any
) {
  const out = [];
  if (user.permissions.length > 0) {
    for (let i = 0; i < user.permissions.length; i++) {
      let network_string = "";
      let permission_string;
      const permission = user.permissions[i];
      let button;
      if (String(permission) === String("SuperUser")) {
        network_string = "All";
        permission_string = "SuperUser";
        button = <p>SuperUser can't be removed</p>;
      } else if (permission.Read && permission.Read.network) {
        permission_string = "Read";
        network_string = permission.Read.network;
      } else if (permission.WriteMetadata && permission.WriteMetadata.network) {
        permission_string = "WriteMetadata";
        network_string = permission.WriteMetadata.network;
      } else if (permission.ReadWrite && permission.ReadWrite.network) {
        permission_string = "ReadWrite";
        network_string = permission.ReadWrite.network;
      }
      // now we traverse our permissions to determine if we can remove this permission
      // if we can't we zero out the button for this row
      let we_can_delete = false;
      for (let x = 0; x < our_permissions.length; x++) {
        if (String(our_permissions[x]) === String("SuperUser")) {
          we_can_delete = true;
        } else if (
          our_permissions[x].ReadWrite &&
          our_permissions[x].ReadWrite.network &&
          our_permissions[x].ReadWrite.network === network_string
        ) {
          we_can_delete = true;
        }
      }
      if (we_can_delete) {
        button = (
          <MDButton
            color="error"
            onClick={() => remove_permission(user.username, network_string)}
          >
            Remove Permission
          </MDButton>
        );
      }
      let network_nickname;
      for (let x = 0; x < networks_list.length; x++) {
        if (
          networks_list[x].address === network_string &&
          networks_list[x].name
        ) {
          network_nickname = networks_list[x].name;
        }
      }

      out.push(
        <>
          <tr>
            <td>{user.username}</td>
            <td>{permission_string}</td>
            <td>{network_string}</td>
            <td>{network_nickname}</td>
            <td>{button}</td>
          </tr>
        </>
      );
    }
    return out;
  } else {
    return (
      <tr>
        <td>{user.username}</td>
        <td>NoAccess</td>
        <td>None</td>
        <td>No permissions to remove</td>
      </tr>
    );
  }
}

const Users: React.FC<UsersProps> = (props) => {
  // dropdown for adding a new user
  const [addDropdownOpen, setAddDropdownOpen] = useState<boolean>(false);
  const [addDropdownPermission, setAddDropdownPermission] =
    useState<string>("Read");
  function toggleAddDropDown() {
    setAddDropdownOpen(!addDropdownOpen);
  }
  const [addUserUsername, setAddUserUsername] = useState<string>("");
  const [addUserPassword, setAddUserPassword] = useState<string>("");
  const [addUserNetwork, setAddUserNetwork] = useState<string>("");
  // dropdown for changing an existing user
  const [changeDropdownOpen, setChangeDropdownOpen] = useState<boolean>(false);
  const [changeDropdownPermission, setChangeDropdownPermission] =
    useState<string>("Read");
  function toggleChangeDropDown() {
    setChangeDropdownOpen(!changeDropdownOpen);
  }
  const [changeUserUsername, setChangeUsername] = useState<string>("");
  const [changeUserNetwork, setChangeUserNetwork] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("");
  const [passwordChangeUsername, setPasswordChangeUsername] =
    useState<string>("");
  const [newResetPassword, setNewResetPassword] = useState<string>("");

  let new_user = <></>;
  let change_perms = <></>;
  if (!props.users_list) {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }
  const can_change =
    we_are_superuser(props.our_permissions) ||
    we_can_change_users(props.our_permissions);
  const is_superuser = String(props.our_permissions) === String("SuperUser");

  if (can_change) {
    change_perms = (
      <>
        <hr />
        <h4>Expand permissions of an existing user</h4>
        <p>
          Add permissions for one or more networks to an existing user. You must
          have ReadWrite on the network you are adding the user to
        </p>
        <Grid container>
          <Grid item md={5} sm={12}>
            <MDInput
              fullWidth
              value={changeUserUsername}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setChangeUsername(e.target.value)}
              placeholder="Username"
            />
          </Grid>
          <Grid item md={5} sm={12}>
            <InputGroup>
              <MDInput
                fullWidth
                value={changeUserNetwork}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setChangeUserNetwork(e.target.value)}
                placeholder="0x0000"
              />
            </InputGroup>
          </Grid>
          <Grid item md={1} sm={12}>
            <InputGroup>
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={changeDropdownOpen}
                toggle={toggleChangeDropDown}
              >
                <DropdownToggle caret size="sm">
                  <MDButton variant="text" color="white" size="small">
                    {changeDropdownPermission}
                  </MDButton>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setChangeDropdownPermission("Read")}>
                    Read
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setChangeDropdownPermission("WriteMetadata")}
                  >
                    WriteMetadata
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setChangeDropdownPermission("ReadWrite")}
                  >
                    ReadWrite
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
            </InputGroup>
          </Grid>
        </Grid>


        <MDButton
          color="info"
          onClick={() =>
            props.add_permission(
              changeUserUsername,
              changeUserNetwork,
              changeDropdownPermission
            )
          }
        >
          Change User
        </MDButton>
      </>
    );
    new_user = (
      <>
        <hr />
        <h4>Create a new user</h4>
        <p>
          Creates a new user with the given permission on one or more networks.
          You must have ReadWrite on the network to add users
        </p>
        <Grid container>
          <Grid item md={5} sm={12}>
            <MDInput
              fullWidth
              value={addUserUsername}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setAddUserUsername(e.target.value)}
              placeholder="Username"
            />
          </Grid>
          <Grid item md={5} sm={12}>
            <MDInput
              fullWidth
              value={addUserPassword}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setAddUserPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
          </Grid>
          <Grid item md={5} sm={12}>
            <InputGroup>
              <MDInput
                fullWidth
                value={addUserNetwork}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setAddUserNetwork(e.target.value)}
                placeholder="0x0000"
              />
            </InputGroup>
          </Grid>
          <Grid item md={1} sm={12}>
            <InputGroup>
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={addDropdownOpen}
                toggle={toggleAddDropDown}
              >
                <DropdownToggle caret size="sm">
                  <MDButton variant="text" color="white" size="small">
                    {addDropdownPermission}
                  </MDButton>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setAddDropdownPermission("Read")}>
                    Read
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setAddDropdownPermission("WriteMetadata")}
                  >
                    WriteMetadata
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setAddDropdownPermission("ReadWrite")}
                  >
                    ReadWrite
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
            </InputGroup>
          </Grid>
        </Grid>
        <MDButton
          color="info"
          onClick={() =>
            props.add_user(
              addUserUsername,
              addUserPassword,
              addUserNetwork,
              addDropdownPermission
            )
          }
        >
          Create New User
        </MDButton>
      </>
    );
  }

  let change_password;
  if (!is_superuser) {
    change_password = (
      <>
        <h4>Change your password</h4>
        {props.last_password_change_failed ? (
          <Alert color="danger">Old password is incorrect!</Alert>
        ) : (
          <></>
        )}
        {newPassword !== newPasswordRepeat ? (
          <Alert color="warning">Passwords do not match!</Alert>
        ) : (
          <></>
        )}
        <MDInput
          value={oldPassword}
          onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setOldPassword(e.target.value)}
          placeholder="old password"
          type="password"
        />
        <MDInput
          value={newPassword}
          onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setNewPassword(e.target.value)}
          placeholder="new password"
          type="password"
        />
        <MDInput
          value={newPasswordRepeat}
          onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setNewPasswordRepeat(e.target.value)}
          placeholder="repeat new password"
          type="password"
        />
        <MDButton onClick={() => props.change_password(oldPassword, newPassword)} color="info">
          Change Password
        </MDButton>
      </>
    );
  }
  let reset_password;
  if (is_superuser) {
    reset_password = (
      <>
        <h4>Reset password</h4>
        this is for superusers only and allows you to change others' passwords
        <Grid container>
          <Grid item md={5} sm={12}>
            <MDInput
              fullWidth
              value={passwordChangeUsername}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPasswordChangeUsername(e.target.value)}
              placeholder="User"
            />
          </Grid>
          <Grid item md={5} sm={12}>
            <MDInput
              fullWidth
              value={newResetPassword}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setNewResetPassword(e.target.value)}
              placeholder="New password"
              type="password"
            />
          </Grid>
        </Grid>


        <MDButton
          color="info"
          onClick={() =>
            props.reset_password(passwordChangeUsername, newResetPassword)
          }
        >
          Change Password
        </MDButton>
      </>
    );
  }

  return (
    <div>
      <Table borderless>
        <thead>
          <tr>
            <th>Username</th>
            <th>Permission</th>
            <th>Network Address</th>
            <th>Network Name</th>
            <th>Remove This Permission</th>
          </tr>
        </thead>
        <tbody>
          {props.users_list.map((user: any) =>
            generate_row(
              user,
              props.our_permissions,
              props.remove_permission,
              props.networks_list
            )
          )}
        </tbody>
      </Table>
      <hr />
      <h4>What do permissions mean?</h4>
      <li>
        <b>Read</b>: This user can see all info about the network but make no
        changes
      </li>
      <li>
        <b>WriteMetadata</b>: This user can change router names and edit
        comments but not send any commands to the routers like reboot, password
        reset, or antenna forwarding
      </li>
      <li>
        <b>ReadWrite</b>: This user can add/remove accounts like the ones you
        see here they can also change descriptions, write notes, reset
        passwords, and forward antennas
      </li>
      <hr />
      {reset_password}
      {change_password}
      {change_perms}
      {new_user}
    </div>
  );
};

export default Users;
