import React from "react";
import { Spinner } from "reactstrap";
import { useState } from "react";
import { NetworkEntry } from "../types";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";

interface NotesProps {
  network_to_edit: NetworkEntry;
  submit: Function;
}

const Notes: React.FC<NotesProps> = (props) => {
  const [notes, setNotes] = useState<string | null>(null);
  if (!props.network_to_edit) {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  const network = props.network_to_edit;
  let local_notes: string;
  if (network && network.notes !== null && notes === null) {
    local_notes = network.notes;
  } else if (notes !== null) {
    local_notes = notes;
  } else {
    local_notes = "";
  }
  return (
    <MDBox width="100%" display="inline-block">
      <Grid container >
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
          <MDInput
            multiline
            rows={15}
            fullWidth
            value={local_notes}
            placeholder={local_notes}
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setNotes(e.target.value)}
          />
        </Grid>
      </Grid>

      <MDButton
        color="secondary"
        onClick={() => {
          props.submit(local_notes, network.address);
          setNotes(null);
        }}
      >
        Save
      </MDButton>{" "}
    </MDBox >
  );
};

export default Notes;
