/* eslint-disable prefer-const */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

function routes_landing() {
  return [
    {
      type: "collapse",
      name: "Users",
      key: "users",
      route: "?devices/users/",
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Customers",
      key: "customers",
      route: "?devices/customers/",
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Documentation",
      key: "documentation",
      href: "https://docs.althea.net/",
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Report an Issue",
      key: "bugreport",
      href: "https://docs.google.com/forms/d/1bg48Cdd3w6DGjTTdBq_LW5iKw1s_46XPLsVEMCM0L44/viewform?edit_requested=true",
      noCollapse: true,
    },
  ];
}

export function routes_network(operator_address: string) {
  const monitor_url = "?" + operator_address + "/monitor/";
  const tools_url = "?" + operator_address + "/settings/";
  const alerts_url = "?" + operator_address + "/alerts/";
  const notes_url = "?" + operator_address + "/notes/";

  let res = [
    {
      type: "collapse",
      name: "Monitor",
      key: "monitor",
      route: monitor_url,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      route: tools_url,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Notes",
      key: "notes",
      route: notes_url,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Alerts",
      key: "alerts",
      route: alerts_url,
      noCollapse: true,
    },
  ];


  if (operator_address === "unattached" || operator_address === "devices") {
    return res;
  } else {
    res.push({
      type: "collapse",
      name: "Overview",
      key: "overview",
      noCollapse: true,
      route: ""
    });
    return res
  }
}

export default routes_landing;