/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Declaring props types for DefaultInfoCard
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  total_operator_income?: string;
  total_bandwidth_income?: string;
  [key: string]: any;
}

function EarningsCard({
  total_bandwidth_income,
  total_operator_income,
}: Props): JSX.Element {
  return (
    <Card>
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Earnings
        </MDTypography>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular">
          Computed as a 30 day rolling month
        </MDTypography>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular">
          Bandwidth income includes relays
        </MDTypography>
        <Divider />

        <MDTypography variant="h6" fontWeight="medium">
          Operator Fee Income
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          ${total_operator_income} / month
        </MDTypography>

        <MDTypography variant="h6" fontWeight="medium">
          Bandwidth Income
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          ${total_bandwidth_income} / month
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Declaring default props for DefaultInfoCard
EarningsCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

export default EarningsCard;
