import {
  DeviceEntryReturn,
  ExpectedNetworkRates,
  OperatorToolsSettingsSubmission,
  OperatorUpdateMessage,
} from "./types";

const seconds_per_month = 2628000;
const wei_per_eth = Math.pow(10, 18);
const bytes_per_gb = Math.pow(1000, 3);

export function wei_to_eth(input: number) {
  return input / wei_per_eth;
}

export function eth_to_wei(input: number) {
  return input * wei_per_eth;
}

export function wei_per_byte_to_dollars_per_gb(input: number) {
  return (input * bytes_per_gb) / wei_per_eth;
}

export function dollars_per_gb_to_wei_per_byte(input: number) {
  return (input * wei_per_eth) / bytes_per_gb;
}

export function wei_per_second_to_dollars_per_month(input: number) {
  const wei_per_month = input * seconds_per_month;
  return wei_per_month / wei_per_eth;
}

export function dollars_per_month_to_wei_per_second(input: number) {
  const dollars_per_second = input / seconds_per_month;
  return dollars_per_second * wei_per_eth;
}

export function to_fixed_number(input: number) {
  return Number(input.toFixed(2));
}

export function we_are_superuser(our_permissions: Array<any>) {
  if (our_permissions === null) {
    return false;
  }
  for (let x = 0; x < our_permissions.length; x++) {
    if (String(our_permissions[x]) === String("SuperUser")) {
      return true;
    }
  }
  return false;
}

export function we_are_read_write_user(
  our_permissions: Array<any>,
  network: string
) {
  for (let x = 0; x < our_permissions.length; x++) {
    if (
      String(our_permissions[x]) === String("SuperUser") ||
      (our_permissions[x].ReadWrite &&
        our_permissions[x].ReadWrite.network &&
        our_permissions[x].ReadWrite.network === network)
    ) {
      return true;
    }
  }
  return false;
}

// if we have the perms to change anything users related
export function we_can_change_users(our_permissions: Array<any>) {
  for (let x = 0; x < our_permissions.length; x++) {
    if (our_permissions[x].ReadWrite && our_permissions[x].ReadWrite.network) {
      return true;
    }
  }
  return false;
}

// these are manually updated not sure that there's any
// way to automate that because it's a human decision.
// might be able to have the package release parsed automatically
// which would remove the need to update it here and on the updates
// server as well
export const stable_versions = [
  "KeyLTE 21 RC12",
  "Beta 20 RC35",
  "Beta 20 RC37",
  "Beta 21 RC20",
  "Beta 22 RC5",
];
export const known_bad_versions = [
  "Beta 18 RC9",
  "Beta 18 RC12",
  "Beta 18 RC11",
  "Beta 19 RC1",
  "Beta 19 RC2",
  "Beta 19 RC3",
  "Beta 19 RC4",
  "Beta 19 RC5",
  "Beta 19 RC6",
  "Beta 19 RC7",
  "Beta 19 RC8",
  "Beta 19 RC9",
  "Beta 19 RC13",
  "Beta 19 RC14",
  "Beta 19 RC15",
  "Beta 19 RC16",
  "Beta 19 RC19",
  "Beta 20 RC18",
  "Beta 20 RC21",
  "Beta 20 RC22",
  "Beta 20 RC26",
  "Beta 21 RC10",
  "Beta 21 RC9",
  "Beta 21 RC8",
  "Beta 21 RC7",
  "Beta 21 RC6",
  "Beta 21 RC5",
  "Beta 21 RC4",
  "Beta 21 RC3",
  "Beta 21 RC2",
  "Beta 21 RC1",
];

export function display_version(device: any) {
  const semvar = device.version.split(".");
  const major_version = parseInt(semvar[0]);
  const minor_version = parseInt(semvar[1]);
  const patch_version = parseInt(semvar[2]);
  let version = "Unknown";
  if (!device.version) {
    version = "<= Beta 13 RC3";
  } else {
    if (major_version === 0 && minor_version >= 17) {
      version = "Beta " + minor_version + " RC" + patch_version;
    } else if (major_version === 0 && minor_version === 5) {
      if (patch_version >= 61) {
        version = "Beta 16 RC" + String(patch_version - 60);
      } else if (patch_version >= 57) {
        version = "Beta 15 RC" + String(patch_version - 56);
      } else if (patch_version >= 47) {
        version = "Beta 14 RC" + String(patch_version - 46);
      } else if (patch_version >= 46) {
        version = "Beta 13 RC" + String(patch_version - 42);
      }
    }
  }
  return version;
}

export function display_version_color(
  device: any,
  version_string: string
): string {
  const semvar = device.version.split(".");
  const minor_version = parseInt(semvar[1]);
  const patch_version = parseInt(semvar[2]);
  if (stable_versions.includes(version_string)) {
    return "success";
  } else if (
    known_bad_versions.includes(version_string) ||
    (minor_version === 18 && patch_version < 10) ||
    minor_version <= 17
  ) {
    return "danger";
  } else {
    // Default color. This should be called something else
    return "secondary";
  }
}

export function remove_from_array(arr: Array<string>, val: string) {
  const ret: Array<string> = [];
  arr.forEach((element) => {
    if (val !== element) {
      ret.push(element);
    }
  });
  return ret;
}

export function EditableSettingsFromOperatorUpdate(
  input: OperatorUpdateMessage,
  fiber_mode: boolean | null,
  expected_rates: ExpectedNetworkRates
) {
  const x: OperatorToolsSettingsSubmission = {
    fiber_mode: fiber_mode,
    shaper_settings: input.shaper_settings,
    relay: wei_per_byte_to_dollars_per_gb(input.relay),
    gateway: wei_per_byte_to_dollars_per_gb(input.gateway),
    phone_relay: wei_per_byte_to_dollars_per_gb(input.phone_relay),
    max: wei_per_byte_to_dollars_per_gb(input.max),
    operator_fee: wei_per_second_to_dollars_per_month(input.operator_fee),
    warning: to_fixed_number(wei_to_eth(input.warning)),
    expected_upload_speed: expected_rates.upload_speed,
    expected_download_speed: expected_rates.download_speed,
    expected_latency: expected_rates.latency,
  };

  return x;
}

export interface OperatorActionProps {
  devices_to_view: DeviceEntryReturn[];
  our_permissions: any;
  submit_operator_action: Function;
  set_wifi_actions_toggle: Function;
  new_operator_address: string;
  set_new_operator_address: Function;
  submit_device_network: Function;
}
export interface SuperUserOperatorActionProps {
  device_to_view: any;
  our_permissions: any;
  submit_operator_action: Function;
  update_auth_keys: string;
  set_update_auth_keys: Function;
}
export interface SecondaryActionProps {
  device_to_view: any;
  our_permissions: any;
  new_text: string;
  set_new_text: Function;
  submit_secondary_action: Function;
}
