import React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Status } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

interface FilterModalProps {
  status_map: Status[];
  flagged_status: boolean[];
  is_open: boolean;
  toggle: Function;
  setFlaggedRouterStatus: Function;
}
const FilterModal: React.FC<FilterModalProps> = (props) => {
  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox px={2} py={10} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Filter Router Statuses
              </ModalHeader>
              <ModalBody>
                {props.flagged_status.map((checked: boolean, index) =>
                  no_gray(
                    props.status_map,
                    checked,
                    index,
                    props.setFlaggedRouterStatus,
                    props.flagged_status
                  )
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};
/// This function is used to remove all statuses whose status are not an issue which the current color is gray.
function no_gray(
  status_map: Status[],
  checked: boolean,
  index: number,
  set_flagged_status: Function,
  flagged_status: boolean[]
) {
  if (status_map[index].color !== "#6c757d") {
    return (
      <div key={index}>
        <label style={{ marginLeft: 5 }}>{status_map[index].status}</label>
        <input
          style={{ marginLeft: 5 }}
          type="checkbox"
          defaultChecked={checked}
          onChange={() => {
            checked = !checked;
            flagged_status[index] = checked;
            set_flagged_status(flagged_status);
          }}
        />
      </div>
    );
  }
  return <></>;
}

export default FilterModal;
