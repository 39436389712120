import React, { useEffect, useState } from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  Input,
} from "reactstrap";
import { CustomerEntryReturn, NetworkEntry } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { we_are_read_write_user } from "utils";

interface CustomerModalProp {
  device_to_edit: any;
  is_open: boolean;
  toggle: Function;
  customer_to_edit: CustomerEntryReturn;
  submit_customer: Function;
  our_permissions: any;
  network: NetworkEntry;
}

const CustomerModal: React.FC<CustomerModalProp> = (props) => {
  if (!props.customer_to_edit) {
    return (
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            display="flex"
            style={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <MDBox p={5} textAlign="center" justifyContent="center">
              <Card>
                <ModalBody>
                  {"Cannot find a customer for this device entry!"}
                </ModalBody>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    );
  }
  // allowed fields to edit on Customer Entry from this modal
  const [customerNickname, setCustomerNickname] = useState<string | null>(null);
  let local_name: string;
  if (props.customer_to_edit && props.customer_to_edit.nickname !== null && customerNickname === null) {
    local_name = props.customer_to_edit.nickname;
  } else if (customerNickname !== null) {
    local_name = customerNickname;
  } else {
    local_name = "";
  }
  const [customerNotes, setCustomerNotes] = useState<string | null>(null);
  let local_notes: string;
  if (props.customer_to_edit && props.customer_to_edit.notes !== null && customerNotes === null) {
    local_notes = props.customer_to_edit.notes;
  } else if (customerNotes !== null) {
    local_notes = customerNotes;
  } else {
    local_notes = "";
  }

  // fields that stay unchanged
  const account_id = props.customer_to_edit.account_id;
  const attached_devices = props.customer_to_edit.attached_devices;

  const [searchID, setSearchID] = useState<number | null>(0);
  let local_search: number;
  if (searchID !== null && searchID !== 0) {
    local_search = searchID;
  } else {
    local_search = 0;
  }

  let reassignCustomer = <></>;

  if (props.our_permissions && we_are_read_write_user(props.our_permissions, props.network.address)) {
    reassignCustomer = (
      <p>
        <Label>
          <b>Reassign Customer </b>
        </Label>
        <InputGroup>
          <MDBox p={1}>
            <MDTypography variant="button">New ID</MDTypography>
          </MDBox>
          <Input
            type="number"
            value={local_search}
            id="seachid"
            placeholder=""
            onChange={(e) => setSearchID(Number(e.target.value))}
          />
        </InputGroup>
        <p />
        <Button
          color="primary"
          style={{
            marginLeft: 5,
          }}
          onClick={async () => {
            await props.submit_customer(
              props.device_to_edit,
              searchID,
              "",
              "",
              true
            );
            props.toggle();
          }}
        >
          Assign
        </Button>
      </p>
    );
  }
  useEffect(() => {
    setCustomerNickname(props.customer_to_edit.nickname);
    setCustomerNotes(props.customer_to_edit.notes);
    setSearchID(null);
  }, [props.customer_to_edit]);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          justifyContent="center"
          display="flex"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox p={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Customer Information
              </ModalHeader>
              <ModalBody>
                <p>
                  <Label>
                    <b>Current Customer </b>
                  </Label>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">ID</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      readOnly
                      id="account_id"
                      value={account_id}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Nickname</MDTypography>
                    </MDBox>
                    <Input
                      type="text"
                      value={local_name}
                      placeholder={local_name}
                      onChange={(e) => setCustomerNickname(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">Notes</MDTypography>
                    </MDBox>
                    <Input
                      type="textarea"
                      value={local_notes}
                      placeholder={local_notes}
                      onChange={(e) => setCustomerNotes(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <MDBox p={1}>
                      <MDTypography variant="button">
                        Owned Devices
                      </MDTypography>
                    </MDBox>
                    <Input
                      type="textarea"
                      readOnly
                      id="owned_devices"
                      value={attached_devices
                        .map((device) =>
                          device.nickname === null || device.nickname === ""
                            ? device.wg_public_key
                            : device.nickname
                        )
                        .join(", ")}
                    />
                  </InputGroup>
                  <p />
                  <Button
                    color="primary"
                    onClick={async () => {
                      await props.submit_customer(
                        props.device_to_edit,
                        account_id,
                        local_name,
                        local_notes,
                        false
                      );
                      props.toggle();
                    }}
                  >
                    Save
                  </Button>
                </p>
                {reassignCustomer}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  Close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CustomerModal;
