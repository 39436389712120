/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Declaring props types for DefaultInfoCard
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  users?: number;
  relays?: number;
  average_spending?: string;
  median_spending?: string;
  average_deposit?: string;
  median_deposit?: string;
  average_relay_spending?: string;
  median_relay_spending?: string;
  [key: string]: any;
}

function AverageUserCard({
  users,
  relays,
  average_spending,
  median_spending,
  average_deposit,
  median_deposit,
  average_relay_spending,
  median_relay_spending,
}: Props): JSX.Element {
  return (
    <Card>
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Average User
        </MDTypography>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular">
          Computed as a 30 day rolling month
        </MDTypography>
        <Divider />

        <MDTypography variant="h6" fontWeight="medium">
          Users / Relays
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          {users} | {relays}
        </MDTypography>

        <MDTypography variant="h6" fontWeight="medium">
          Monthly cost: average / median
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          ${average_spending} | ${median_spending}
        </MDTypography>

        <MDTypography variant="h6" fontWeight="medium">
          Monthly deposit amount: average / median
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          ${average_deposit} | ${median_deposit}
        </MDTypography>

        <MDTypography variant="h6" fontWeight="medium">
          Relay cost: average / median
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular">
          ${average_relay_spending} | ${median_relay_spending}
        </MDTypography>

      </MDBox>
    </Card>
  );
}

// Declaring default props for DefaultInfoCard
AverageUserCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

export default AverageUserCard;
