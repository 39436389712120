import React from "react";
import IpAddress from "ip-address";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  Label,
} from "reactstrap";
import { useState } from "react";
import { DeviceEntryReturn } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

interface ForwardingModalProps {
  device_to_edit: DeviceEntryReturn;
  is_open: boolean;
  toggle: Function;
  submit: Function;
}

const ForwardModal: React.FC<ForwardingModalProps> = (props) => {
  let id: any;
  const [ip, setIp] = useState("");
  const [port, setPort] = useState(0);

  const [listOfPorts, setListOfPort] = useState(443);

  if (props.device_to_edit) {
    id = props.device_to_edit.id;
  } else {
    return <></>;
  }
  let disable_start = false;
  if (port > 65_535 || port === 0) {
    disable_start = true;
  }
  const validated_ip = new IpAddress.Address4(ip);
  if (!validated_ip.isValid()) {
    disable_start = true;
  }

  let install_details = <></>;
  if (props.device_to_edit.install_details) {
    let client_antenna_ip = "";
    if (props.device_to_edit.install_details.client_antenna_ip != null) {
      client_antenna_ip =
        props.device_to_edit.install_details.client_antenna_ip;
    }
    install_details = (
      <>
        <Label>
          <b>CPE IP:</b>
        </Label>
        <InputGroup>
          <Input id="CPE IP" readOnly value={client_antenna_ip} />
        </InputGroup>
        <Label>
          <b>Relay Antenna IPs:</b>
        </Label>
        <InputGroup>
          <Input
            id="relay IP"
            readOnly
            value={props.device_to_edit.install_details.relay_antennas}
          />
        </InputGroup>
        <Label>
          <b>Equipment details:</b>
        </Label>
        <InputGroup>
          <Input
            id="equipment"
            readOnly
            value={props.device_to_edit.install_details.equipment_details}
          />
        </InputGroup>
        <b>Quick access - Relayer addresses:</b>
        {props.device_to_edit.install_details.relay_antennas.map(
          (address: any) => (
            <InputGroup>
              <Button
                onClick={() => {
                  props.submit(address, port, id);
                }}
              >
                {address}
              </Button>
            </InputGroup>
          )
        )}
        <b>Set port for list of relayer addresses:</b>
        <Input
          type="number"
          value={listOfPorts}
          placeholder="Port"
          onChange={(e) => setListOfPort(parseInt(e.target.value))}
        />
      </>
    );
  }
  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox px={2} py={10} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Antenna Dashboard
              </ModalHeader>
              <ModalBody>
                Please input the Ip address and port of the Antenna you would
                like to access from this device. Keep in mind that Ubiquiti will
                always use port 443 while other manufactuers like VJJT will
                always use port 80.
                <hr />
                {install_details}
                <hr />
                <InputGroup>
                  <Input
                    type="text"
                    value={ip}
                    placeholder="IP Address"
                    onChange={(e) => setIp(e.target.value)}
                  />
                  <Input
                    type="number"
                    value={port}
                    placeholder="Port"
                    onChange={(e) => setPort(parseInt(e.target.value))}
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={disable_start}
                  color="warning"
                  onClick={() => {
                    props.submit(ip, port, id);
                    props.toggle();
                  }}
                >
                  Begin forwarding
                </Button>{" "}
                <Button color="secondary" onClick={() => props.toggle()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ForwardModal;
