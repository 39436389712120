import React, { useState } from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Table,
  Label,
  InputGroup,
  Input,
  DropdownItem,
} from "reactstrap";
import { CardBody, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { DeviceEntryReturn } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

function print_shaper_speed(input: any) {
  if (input) {
    return input + " mbps";
  } else {
    return "Unlimited";
  }
}

interface ConnectionInfoProps {
  device_to_view: DeviceEntryReturn;
}

const ConnectionInfo: React.FC<ConnectionInfoProps> = (props) => {
  const neigh_rtt_info = props.device_to_view.mean_latency_exit;
  const exit_rtt_info = props.device_to_view.mean_latency_exit_neighbor;
  const neigh_packet_loss = props.device_to_view.neighbor_packet_loss_stats;
  return (
    <>
      <Label>
        <b>Exit Latency (lowest / average):</b>
      </Label>
      <InputGroup>
        <Input
          id="exitAvg"
          readOnly
          value={`${props.device_to_view.lowest_exit.toFixed(
            2
          )}ms / ${exit_rtt_info.toFixed(2)}ms`}
        />
      </InputGroup>
      <Label>
        <b>Upstream Latency (lowest / average):</b>
      </Label>
      <InputGroup>
        <Input
          id="exitAvg"
          readOnly
          value={`${props.device_to_view.lowest_exit_neighbor.toFixed(
            2
          )}ms / ${neigh_rtt_info.toFixed(2)}ms`}
        />
      </InputGroup>
      <Label>
        <b>Upstream Packet loss (5 minute average / Power On average):</b>
      </Label>
      <InputGroup>
        <Input
          id="exitAvg"
          readOnly
          value={`${neigh_packet_loss.five_minute_average.toFixed(
            2
          )}% / ${neigh_packet_loss.average.toFixed(2)}%`}
        />
      </InputGroup>
    </>
  );
};

interface RegisterToExitModalProps {
  is_open: boolean;
  device: DeviceEntryReturn;
  toggle: Function;
  submit_operator_action: Function;
  device_to_reg: any;
}

const RegisterToExitModal: React.FC<RegisterToExitModalProps> = (props) => {
  const [dropDownOpen, setDropDown] = useState<any>(null);
  const [chosenExit, setChosenExit] = useState<any>("Choose Exit");
  const [applyingChanges, setApplyingChanges] = useState<any>("");

  const dropDownToggle = () => {
    setDropDown(!dropDownOpen);
  };

  function exitOptions(dev: DeviceEntryReturn) {
    const ret = [];

    if (dev.exit_con === null) {
      return;
    }

    // we use any exit in available_exits as they are all us west

    ret.push(
      <DropdownItem key={0}>
        <div onClick={(e) => setChosenExit(e.currentTarget.textContent)}>
          US West
        </div>
      </DropdownItem>
    );
    return ret;
  }

  function submit_exit(dev: DeviceEntryReturn) {
    if (dev.exit_con === null) {
      return;
    }

    // We hardcode a US west wg key here to return to backend. This makes
    // sense since all exits received here from the backend are in US west.
    // In the future this can be expanded to account for mulitple exit clusters

    const ret = "registerclient_" + dev.exit_con?.available_exits[0];

    console.log(
      "about to register " +
        props.device_to_reg.nickname +
        " with string: " +
        ret
    );
    setApplyingChanges("Applying changes, you may close this window");
    props.submit_operator_action(props.device_to_reg, ret, false);
  }

  function close_button() {
    setApplyingChanges("");
    props.toggle();
  }

  return (
    <Modal
      open={props.is_open}
      onClose={() => close_button()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll" }}
          display="flow"
        >
          <MDBox px={2} py={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => close_button()}>
                Exit Registration
              </ModalHeader>
              <ModalBody>Choose an exit</ModalBody>
              <Container>
                <CardBody>
                  <div>
                    <span>
                      <Dropdown isOpen={dropDownOpen} toggle={dropDownToggle}>
                        <DropdownToggle caret>{chosenExit}</DropdownToggle>
                        <DropdownMenu>{exitOptions(props.device)}</DropdownMenu>
                      </Dropdown>
                    </span>
                    <br />
                  </div>
                  {applyingChanges}
                </CardBody>
              </Container>

              <ModalFooter>
                <Button
                  color="secondary"
                  disabled={chosenExit === "Choose Exit"}
                  onClick={() => submit_exit(props.device)}
                >
                  Register
                </Button>
                <Button color="secondary" onClick={() => close_button()}>
                  Close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

interface NeighborInfoIface {
  // the NeighborStatus struct in Althea types
  neighbor: any;
  devices: any;
  toggle: Function;
  choose_router: Function;
  // set of wg keys
  exit_list: Set<string>;
}

const NeighborInfo: React.FC<NeighborInfoIface> = (props) => {
  function set_up_router() {
    props.choose_router(props.neighbor.id);
    props.toggle();
  }

  const register = [];
  if (props.neighbor.is_registered === null) {
    register.push("Backend error");
  } else if (props.neighbor.is_registered === true) {
    register.push("Registered");
  } else if (props.exit_list.has(props.neighbor.id.wg_public_key)) {
    // an exit
    register.push("");
  } else {
    register.push(
      <Button color="secondary" onClick={() => set_up_router()}>
        Register
      </Button>
    );
  }
  return (
    <tr>
      <td>{props.neighbor.neighbor_name}</td>
      <td>{print_shaper_speed(props.neighbor.shaper_speed)}</td>
      <td>{String(props.neighbor.enforced)}</td>
      <td>{register}</td>
    </tr>
  );
};

function get_upstream_path(device: DeviceEntryReturn) {
  const ret = [];

  for (let i = 0; i < device.upstream_path.map.length; i++) {
    const node = device.upstream_path.map[i];
    const dis_name = node.ops_name === null ? node.id.nickname : node.ops_name;
    ret.push(
      <InputGroup key={node.id.wg_public_key}>
        <Input id={node.id.mesh_ip} readOnly value={dis_name} />
        <Input
          id={node.id.mesh_ip}
          readOnly
          value={"Latency to Exit: " + node.latency_to_exit + " ms"}
        />
      </InputGroup>
    );
  }

  return <InputGroup>{ret}</InputGroup>;
}

interface TopologyModalProps {
  device_to_view: DeviceEntryReturn;
  all_devices: Array<DeviceEntryReturn>;
  is_open: boolean;
  toggle: Function;
  submit_exit_action: Function;
}

const TopologyModal: React.FC<TopologyModalProps> = (props) => {
  const [registerIsOpen, setRegisterIsOpen] = useState<boolean>(false);
  const [chosenRouter, setChosenRouter] = useState<any>(null);

  const registerIsOpenToggle = () => {
    setRegisterIsOpen(!registerIsOpen);
  };

  if (!props.device_to_view || !props.all_devices) {
    return <></>;
  }

  const all_devices = props.all_devices;
  const device = props.device_to_view;

  const exit_list = new Set(props.device_to_view.exit_con?.available_exits);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll" }}
          display="flow"
        >
          <MDBox px={2} py={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Neighbor Details
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Label>
                    <b>This routers upstream path:</b>
                  </Label>
                  <InputGroup>{get_upstream_path(device)}</InputGroup>
                  <ConnectionInfo device_to_view={props.device_to_view} />
                  <Label>
                    <b>Neighbor Count:</b>
                  </Label>
                  <InputGroup>
                    <Input
                      id="neighborCount"
                      readOnly
                      value={device.last_neighbor_info.length}
                    />
                  </InputGroup>
                  <Table>
                    <thead>
                      <tr>
                        <th>Neighbors Name</th>
                        <th>Speed</th>
                        <th>Enforced</th>
                        <th>Register</th>
                      </tr>
                    </thead>
                    <tbody>
                      {device.last_neighbor_info.map((device: any) => (
                        <NeighborInfo
                          key={device.id.mesh_ip}
                          neighbor={device}
                          devices={all_devices}
                          toggle={registerIsOpenToggle}
                          choose_router={setChosenRouter}
                          exit_list={exit_list}
                        />
                      ))}
                    </tbody>
                  </Table>
                </Container>
                <RegisterToExitModal
                  is_open={registerIsOpen}
                  device={device}
                  toggle={registerIsOpenToggle}
                  submit_operator_action={props.submit_exit_action}
                  device_to_reg={chosenRouter}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TopologyModal;
