import React from "react";
import { Alert } from "reactstrap";
import { NotificationReturn } from "../types";
import MDButton from "components/MDButton";

function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

interface AlertsProps {
  notification_queue: Array<NotificationReturn>;
  dismiss_alert: Function;
  dismiss_all_alerts: Function;
}

const alerts: React.FC<AlertsProps> = (props) => {
  if (!props.notification_queue) {
    return <></>;
  }
  const notification_queue = props.notification_queue;

  if (notification_queue.length > 0) {
    return (
      <div>
        <div>
          <MDButton onClick={() => props.dismiss_all_alerts()}>
            Dismiss all
          </MDButton>
        </div>
        <hr />
        {notification_queue.map((alert: any) => (
          <div style={{ padding: 5 }}>
            <DisplaySingleAlert alert={alert} dismiss={props.dismiss_alert} />
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <Alert color="success">No Alerts!</Alert>
      </div>
    );
  }
};

interface DisplaySingleAlertProps {
  alert: NotificationReturn;
  dismiss: Function;
}

const DisplaySingleAlert: React.FC<DisplaySingleAlertProps> = (props) => {
  const onDismiss = () => props.dismiss(props.alert.notification_id);
  return (
    <Alert color="primary" toggle={onDismiss}>
      <div style={{ overflow: "auto" }}>
        <div style={{ float: "left" }}>
          <p>{props.alert.notification_message}</p>
        </div>
        <div style={{ float: "right" }}>
          <p>{secondsToDhms(props.alert.triggered)} ago</p>
        </div>
      </div>
    </Alert>
  );
};

export default alerts;
