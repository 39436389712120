/**
=========================================================
This file is for network cards which are the cards populated by
individual network information to be displayed on the landing page.
*/

// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

function address_to_url(address: string) {
    return "/?" + address + "/monitor";
}

interface MaybeDeleteButtonProps {
    network: any;
    call: Function;
    permissions: any;
}

const MaybeDeleteButton: React.FC<MaybeDeleteButtonProps> = (props) => {
    if (
        props.network.active === 0 &&
        String(props.permissions) === String("SuperUser")
    ) {
        return (
            <DropdownItem onClick={() => props.call(props.network.address)}>
                Delete Network
            </DropdownItem>
        );
    } else {
        return <></>;
    }
};

// Declaring props types for DefaultInfoCard
interface Props {
    network: any;
    name: string;
    address?: any;
    active?: any;
    inactive?: any;
    delete_network?: Function;
    trigger_name_modal?: Function;
    trigger_overview_modal?: Function;
    permissions: any;
    balance?: any;
}

function NetworkCard({
    network,
    name,
    address,
    active,
    inactive,
    trigger_name_modal,
    trigger_overview_modal,
    delete_network,
    permissions,
    balance,
}: Props): JSX.Element {
    let show_overview;
    if ((address == "devices") || (address == "unattached")) {
        show_overview = <></>
    } else {
        show_overview =
            <Grid item>
                <MDButton
                    color="info"
                    onClick={() => trigger_overview_modal(network)}
                >
                    Overview
                </MDButton>
            </Grid>
    }
    const maybe_delete = <MaybeDeleteButton
        network={network}
        call={delete_network}
        permissions={permissions} />;
    // ButtonDropdown open state 
    const [dropdownOpen, setOpen] = React.useState(false);
    return (
        <Grid item xs={12} md={5.5} lg={3.5} m={1}>
            <Card>
                <MDBox width="100%">
                    <MDBox pt={2} pb={2} px={2} textAlign="center" lineHeight={1.25}>
                        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                            {name}
                        </MDTypography>
                        <MDTypography component="a" href={address_to_url(address)} variant="body2" fontWeight="medium" color="info">
                            {address}
                        </MDTypography>
                        <MDTypography variant="subtitle2" fontWeight="regular">
                            Active: {active} | Inactive: {inactive}
                        </MDTypography>
                        <MDTypography variant="subtitle2" fontWeight="regular">
                            Average Balance: {balance}
                        </MDTypography>
                        <p />
                        <Grid container spacing={3} justifyContent="center">

                            {show_overview}
                            <Grid item>
                                <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
                                    isOpen={dropdownOpen}>

                                    <DropdownToggle size="sm" caret>
                                        <MDButton variant="text" color="white" size="small">
                                            Options
                                        </MDButton>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => trigger_name_modal(network)} value="id">
                                            Change Name
                                        </DropdownItem>
                                        {maybe_delete}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </Grid >
    );
}

export default NetworkCard;
