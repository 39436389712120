/* eslint-disable prefer-const */
import React from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
  CardBody,
} from "reactstrap";
import {
  DeviceEntryReturn,
  RouterLatencyStruct,
  UsageDataReturn,
  UsageTracker,
} from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { create_usage_charts } from "./OverviewModal";

interface LatenciesModalProps {
  device_to_view: DeviceEntryReturn;
  latencies: RouterLatencyStruct | null;
  usage_data: UsageDataReturn;
  is_open: boolean;
  toggle: Function;
  toggled_number_of_days: number;
  network: any;
}

function get_network_latency(network_latencies_data: RouterLatencyStruct) {
  const exit_neighbor_latency = network_latencies_data.exit_neighbor_latency;
  const exit_neighbor_time = network_latencies_data.exit_neighbor_time;

  const exit_latency_latency = network_latencies_data.exit_latency_latency;

  const res: {
    Timestamps: string[];
    LatencyToExit: number[];
    LatencyToUpstream: number[];
  }[] = [];

  let iterate;
  const ignored_data_points_exit = 0;
  const ignored_data_points_upstream = 0;

  let timestamps = [];
  let toExit = [];
  let toUpstream = [];

  // Each data points that is null/black we can add to the total number
  // of skipped points so we can make sure the average isn't affected.
  // since we divide by the total number of points which is computed by
  // iterating.
  //
  // We also divide the value by zero in order to create an impossible value
  // so the graph displays a gap
  for (iterate = 0; iterate < exit_neighbor_latency.length; iterate++) {
    const t = new Date(exit_neighbor_time[iterate]);
    let formatted = t.toISOString();
    formatted = formatted.substring(0, formatted.length - 8);
    timestamps.push(formatted);

    latency_average_exit += exit_latency_latency[iterate];
    toExit.push(exit_latency_latency[iterate]);

    latency_average_upstream += exit_neighbor_latency[iterate];
    toUpstream.push(exit_neighbor_latency[iterate]);
  }
  res.push({
    Timestamps: timestamps,
    LatencyToExit: toExit,
    LatencyToUpstream: toUpstream,
  });
  latency_average_exit /=
    exit_neighbor_latency.length - ignored_data_points_exit;
  latency_average_upstream /=
    exit_neighbor_latency.length - ignored_data_points_upstream;

  return res;
}

// these must be added to an array the way net latency is returned
// for each entry in res we have a timestamp and an up and down point
export function get_bandwidth_usage(data: UsageTracker) {
  const res_client: {
    Timestamps: string[];
    UpUsage: number[];
    DownUsage: number[];
  }[] = [];
  const res_relay: {
    Timestamps: string[];
    UpUsage: number[];
    DownUsage: number[];
  }[] = [];
  let iterate;
  // if data.client_bandwidth is null, we have a device that simply does not have saved usage data,
  // and no points will be displayed.
  if (
    data == null ||
    (data.client_bandwidth == null && data.relay_bandwidth == null)
  ) {
    return [res_client, res_relay];
  }
  // for each of client and relay we need to iterate, but because they can possibly be different lengths
  // we cannot iterate over them both in one pass or graph data will be skewed
  const client_copy = data.client_bandwidth.map((o) => Object.assign({}, o));
  const relay_copy = data.relay_bandwidth.map((o) => Object.assign({}, o));
  let client_timestamps = [];
  let client_up = [];
  let client_down = [];
  for (iterate = 0; iterate < data.client_bandwidth.length; iterate++) {
    const usage_hour_client = client_copy[iterate];
    const d = new Date(usage_hour_client.index * 3600000);
    let dstring = d.toISOString();
    dstring = dstring.substring(0, dstring.length - 8);
    client_timestamps.push(dstring);
    client_up.push(
      parseFloat((usage_hour_client.up * 0.000000002222).toFixed(3))
    );
    client_down.push(
      parseFloat((usage_hour_client.down * 0.000000002222).toFixed(3))
    );
  }
  res_client.push({
    Timestamps: client_timestamps,
    UpUsage: client_up,
    DownUsage: client_down,
  });
  let relay_timestamps = [];
  let relay_up = [];
  let relay_down = [];
  for (iterate = 0; iterate < data.relay_bandwidth.length; iterate++) {
    const usage_hour_relay = relay_copy[iterate];
    const d = new Date(usage_hour_relay.index * 3600000);
    let dstring = d.toISOString();
    dstring = dstring.substring(0, dstring.length - 8);
    relay_timestamps.push(dstring);

    relay_up.push(
      parseFloat((usage_hour_relay.up * 0.000000002222).toFixed(3))
    );
    relay_down.push(
      parseFloat((usage_hour_relay.down * 0.000000002222).toFixed(3))
    );
  }
  res_relay.push({
    Timestamps: relay_timestamps,
    UpUsage: relay_up,
    DownUsage: relay_down,
  });
  return [res_client, res_relay];
}

function format_date(t: Date) {
  const seconds = Math.floor((new Date().getTime() - t.getTime()) / 1000);
  let interval = seconds / 31536000;

  if (interval >= 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

function get_earliest_datapoint(data: RouterLatencyStruct) {
  if (data.exit_latency_time.length === 0) {
    return "No Data";
  } else {
    const ed = data.exit_latency_time[0];
    const t = new Date(ed);
    return format_date(t);
  }
}

function get_latest_datapoint(data: RouterLatencyStruct) {
  if (data.exit_latency_time.length === 0) {
    return "No Data";
  } else {
    const ed = data.exit_latency_time[data.exit_latency_time.length - 1];
    const t = new Date(ed);
    return format_date(t);
  }
}

function get_last_seen_hour(data: UsageDataReturn) {
  if (
    data == null ||
    data.requested_period.last_save_hour == null ||
    data.requested_period.last_save_hour === 0
  ) {
    return "No Data";
  } else {
    const ed = data.requested_period.last_save_hour;
    const t = new Date(ed * 3600000);
    return format_date(t);
  }
}

function get_latency_card(data: RouterLatencyStruct, fiber_mode: boolean) {
  if (data != null) {
    const latency_data = get_network_latency(data);

    const latency_graph_data: Types = {
      labels: latency_data[0].Timestamps,
      datasets: [
        {
          label: "ms to Exit",
          color: "info",
          data: latency_data[0].LatencyToExit,
        },
        {
          label: "ms to Upstream",
          color: "dark",
          data: latency_data[0].LatencyToUpstream,
        },
      ],
    };

    return (
      <CardBody>
        <MDBox mt={1}>
          <MDTypography variant="subtitle2">
            Latency is polled every 5 seconds from the router. Timestamps on
            each datapoint may not be precise relative to other points.
          </MDTypography>
          <p />
        </MDBox>
        <p>
          <span>Starting: {get_earliest_datapoint(data)}</span>
          <br />
          <span>Ending: {get_latest_datapoint(data)}</span>
          <br />
          <span>
            Latency to Exit Average: {latency_average_exit.toFixed(2)} (ms)
          </span>
          <br />
          <span>
            {" "}
            Latency to Upstream Average: {latency_average_upstream.toFixed(
              2
            )}{" "}
            (ms)
          </span>
        </p>

        <DefaultLineChart
          description={
            <Grid container width="100%">
              <Grid item sm={12} md={6} lg={6} width="100%">
                <MDBox>
                  <MDBadgeDot
                    color="info"
                    size="md"
                    badgeContent="Latency to Exit"
                  />
                  <MDBadgeDot
                    color="dark"
                    size="md"
                    badgeContent="Latency to Upstream"
                  />
                </MDBox>
              </Grid>
            </Grid>
          }
          chart={latency_graph_data}
        />
      </CardBody>
    );
  } else if (fiber_mode) {
    return (
      <CardBody>
        <MDBox mt={3}>
          <MDTypography variant="subtitle2">
            No Latency Data for fiber networks.
          </MDTypography>
        </MDBox>
      </CardBody>
    );
  } else {
    return (
      <CardBody>
        <MDBox mt={3}>
          <MDTypography variant="subtitle2">
            No Latency Data for this device over requested timeframe.
          </MDTypography>
        </MDBox>
      </CardBody>
    );
  }
}

function get_bandwidth_card(data: UsageDataReturn) {
  if (
    data != null &&
    get_last_seen_hour(data) !== "No Data" &&
    data.requested_period.client_bandwidth.length != 0 &&
    data.requested_period.relay_bandwidth.length != 0
  ) {
    const [client, relay] = create_usage_charts(data.requested_period);
    return (
      <CardBody>
        <MDBox mt={3}>
          <MDTypography variant="subtitle2">
            Bandwidth Usage is collected once hourly from the router. If viewing
            over longer period than the 1 day option, points shown on the graph
            will be averaged over all hours saved in that time period.
          </MDTypography>
          <p />
          <MDTypography variant="subtitle2">
            Data last collected: {get_last_seen_hour(data)}
          </MDTypography>
        </MDBox>
        {client}
        {relay}
      </CardBody>
    );
  } else {
    return (
      <CardBody>
        <MDBox mt={3}>
          <MDTypography variant="subtitle2">
            No Bandwidth Data for this device over requested timeframe.
          </MDTypography>
          <p />
          <MDTypography variant="subtitle2">
            Data last collected: {get_last_seen_hour(data)}
          </MDTypography>
        </MDBox>
      </CardBody>
    );
  }
}

function get_total_usage(data: UsageDataReturn) {
  if (data === null || data.requested_period.client_bandwidth.length === 0) {
    return <></>;
  }
  const week_total_client_up = parseFloat(
    (data.total_week.client_up * 0.000000001).toFixed(3)
  );
  const week_total_client_down = parseFloat(
    (data.total_week.client_down * 0.000000001).toFixed(3)
  );
  const month_total_client_up = parseFloat(
    (data.total_month.client_up * 0.000000001).toFixed(3)
  );
  const month_total_client_down = parseFloat(
    (data.total_month.client_down * 0.000000001).toFixed(3)
  );
  const week_total_relay_up = parseFloat(
    (data.total_week.relay_up * 0.000000001).toFixed(3)
  );
  const week_total_relay_down = parseFloat(
    (data.total_week.relay_down * 0.000000001).toFixed(3)
  );
  const month_total_relay_up = parseFloat(
    (data.total_month.relay_up * 0.000000001).toFixed(3)
  );
  const month_total_relay_down = parseFloat(
    (data.total_month.relay_down * 0.000000001).toFixed(3)
  );
  return (
    <CardBody>
      <MDBox mt={3}>
        <MDBox flexDirection="column">
          <MDTypography variant="h6" fontWeight="medium" placement="right">
            Total Usage this Week / Month:
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular">
            Upload {week_total_client_up} GB | {month_total_client_up} GB
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular">
            Download {week_total_client_down} GB | {month_total_client_down} GB
          </MDTypography>
        </MDBox>
        <MDBox flexDirection="column">
          <MDTypography variant="h6" fontWeight="medium">
            Total Relayed this Week / Month:
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular">
            Upload {week_total_relay_up} GB | {month_total_relay_up} GB
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular">
            Download {week_total_relay_down} GB | {month_total_relay_down} GB
          </MDTypography>
        </MDBox>
      </MDBox>
    </CardBody>
  );
}

interface Types {
  labels: string[];
  datasets: {
    label: string;
    color:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "light"
      | "dark";
    data: number[];
  }[];
}

let latency_average_exit = 0;
let latency_average_upstream = 0;

const LatenciesModal: React.FC<LatenciesModalProps> = (props) => {
  if (!props.device_to_view) {
    return <></>;
  }

  if (!props.device_to_view) {
    return <></>;
  }
  if (!props.device_to_view) {
    return (
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            style={{ overflowX: "scroll" }}
            display="flow"
          >
            <MDBox px={2} py={5} textAlign="center" justifyContent="center">
              <Card>
                <ModalHeader toggle={() => props.toggle()}>
                  User Latencies
                </ModalHeader>
                <ModalBody>
                  <Spinner></Spinner>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => props.toggle()}>
                    close
                  </Button>
                </ModalFooter>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    );
  }

  const latency = get_latency_card(props.latencies, props.network.fiber_mode);
  const total_usage = get_total_usage(props.usage_data);
  const bandwidth = get_bandwidth_card(props.usage_data);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll" }}
          display="flow"
        >
          <MDBox px={2} py={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Latency and Bandwidth
              </ModalHeader>
              <Container>
                {latency}
                {total_usage}
                {bandwidth}
              </Container>

              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LatenciesModal;
