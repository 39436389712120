import React from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { DeviceEntryReturn } from "../types";
import { wei_to_eth } from "../utils";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

function wei_to_display(input: number) {
  return "$" + wei_to_eth(input).toFixed(2);
}

interface FinanceModalProps {
  device_to_view: DeviceEntryReturn;
  finances: any;
  is_open: boolean;
  toggle: Function;
}

const FinanceModal: React.FC<FinanceModalProps> = (props) => {
  if (!props.device_to_view) {
    return <></>;
  }

  if (!props.finances || !props.device_to_view) {
    return (
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            display="flex"
            style={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <MDBox p={5} ml={20} textAlign="center" justifyContent="center">
              <Card>
                <ModalHeader toggle={() => props.toggle()}>
                  User Finances
                </ModalHeader>
                <ModalBody>
                  <Spinner></Spinner>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => props.toggle()}>
                    close
                  </Button>
                </ModalFooter>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    );
  }
  const week_spending = wei_to_display(props.finances.last_week_spending);
  const week_income = wei_to_display(props.finances.last_week_income);
  const month_spending = wei_to_display(props.finances.last_month_spending);
  const month_income = wei_to_display(props.finances.last_month_income);
  const week_deposit = wei_to_display(props.finances.last_week_deposits);
  const week_withdraw = wei_to_display(props.finances.last_week_withdraws);
  const month_deposit = wei_to_display(props.finances.last_month_deposits);
  const month_withdraw = wei_to_display(props.finances.last_month_withdraws);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          display="flex"
          style={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <MDBox p={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                User Finances
              </ModalHeader>
              <ModalBody>
                Income and spending are funds paid or received for bandwidth,
                deposits and withdraws and funds the user has added or removed
                from their router
                <hr />
                <Label>
                  <b>Spending This Week / Month:</b>
                </Label>
                <InputGroup>
                  <Input
                    id="spending"
                    readOnly
                    value={week_spending + " / " + month_spending}
                  />
                </InputGroup>
                <Label>
                  <b>Income This Week / Month:</b>
                </Label>
                <InputGroup>
                  <Input
                    id="income"
                    readOnly
                    value={week_income + " / " + month_income}
                  />
                </InputGroup>
                <Label>
                  <b>Deposits This Week / Month:</b>
                </Label>
                <InputGroup>
                  <Input
                    id="weekly_deposits"
                    readOnly
                    value={week_deposit + " / " + month_deposit}
                  />
                </InputGroup>
                <Label>
                  <b>Withdraws This Week / Month:</b>
                </Label>
                <InputGroup>
                  <Input
                    id="weekly_withdraws"
                    readOnly
                    value={week_withdraw + " / " + month_withdraw}
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => props.toggle()}>
                  close
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FinanceModal;
