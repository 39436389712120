import React from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import { useState } from "react";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

interface NameModalProps {
  // NetworkEntry | DeviceEntry return, difficult to discriminate on this
  // union type, should probably duplicate this code
  device_to_edit: any;
  is_open: boolean;
  toggle: Function;
  submit: Function;
}

const NameModal: React.FC<NameModalProps> = (props) => {
  const [name, setName] = useState<string | null>(null);
  const device = props.device_to_edit;
  let local_name: string;
  if (device && device.name !== null && name === null) {
    local_name = device.name;
  } else if (device && device.address && name === null) {
    local_name = "";
  } else if (name !== null) {
    local_name = name;
  } else {
    local_name = "";
  }

  return (
    <Modal open={props.is_open} onClose={() => props.toggle()}>
      <Grid container justifyContent="center">
        <Grid item sm={12} md={6} lg={6} m={6} justifyContent="center">
          <MDBox p={5} m={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>Edit Name</ModalHeader>
              <ModalBody>
                <InputGroup>
                  <Input
                    type="text"
                    value={local_name}
                    placeholder={local_name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => {
                    if (device.address) {
                      props.submit(local_name, device.address);
                    } else {
                      props.submit(local_name, device.id);
                    }
                    setName(null);
                    props.toggle();
                  }}
                >
                  Save
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    setName(null);
                    props.toggle();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal >
  );
};

export default NameModal;
