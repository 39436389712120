import React, { useState, forwardRef } from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Form,
  Label,
  Container,
  Spinner,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
} from "reactstrap";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  remove_from_array,
  EditableSettingsFromOperatorUpdate,
  to_fixed_number,
  dollars_per_gb_to_wei_per_byte,
  dollars_per_month_to_wei_per_second,
  eth_to_wei,
} from "../utils";
import {
  NetworkEntry,
  OperatorToolsSettingsSubmission,
  ShaperSettings,
  TimezoneSettings,
} from "../types";
import { SERVER_URL } from "../App";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";

interface PhoneInputProps {
  onChange: Function;
  value: string;
}

const InnerPhoneInput: React.FC<PhoneInputProps> = forwardRef<
  HTMLInputElement,
  PhoneInputProps
>((props, ref) => {
  return (
    <input
      {...props}
      className="form-control"
      onChange={(e) => props.onChange(String(e.target.value))}
      value={props.value}
      ref={ref}
    />
  );
});

interface PhoneListProps {
  notify_list: Array<string>;
  remove: Function;
}

const PhoneList: React.FC<PhoneListProps> = (props) => {
  return (
    <ListGroup>
      {props.notify_list.map((phone: string) => (
        <ListGroupItem key={phone}>
          <div style={{ float: "left" }}>{phone}</div>

          <div style={{ float: "right" }}>
            <MDButton
              color="warning"
              onClick={() => props.remove(phone, props.notify_list)}
            >
              Remove
            </MDButton>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

interface SettingsProps {
  address: string;
  network: NetworkEntry;
  get_network: Function;
  set_network: Function;
}

const Settings: React.FC<SettingsProps> = (props) => {
  // We have a separate network settings object because we are going to modify
  // and then submit this to the server as part of the settings change.
  // It's important to create a new object when saving to trigger a rerender:
  // networkSettings.gateway = Number(e.target.value);
  // setNetworkSettings({ ...networkSettings });
  const [networkSettings, setNetworkSettings] =
    useState<OperatorToolsSettingsSubmission | null>(null);
  // Since shaper settings can come in as a None option type, we need to set a
  // default here on the frontend in order to change any settings.
  const [shaperSettings, setShaperSettings] = useState<ShaperSettings | null>(
    props.network?.settings?.shaper_settings
  );
  // Since fiber mode can come in as a None option type, we need to set a
  // default here on the frontend in order to change any settings.
  const [fiberMode, setFiberMode] = useState<boolean | null>(
    props.network?.fiber_mode
  );
  // and this bool is used to decide when to save the shapersettings so we don't
  // end up saving defaults over a None when nothing has been changed
  const [shaperSettingsChanged, setShaperSettingsChanged] =
    useState<boolean>(false);
  // the list of notifications that are configured on a network.
  // create a new array when saving: setNotificationTypes([...notificationTypes])
  const [notificationTypes, setNotificationTypes] =
    useState<Array<string> | null>(null);
  // timezone settings for when low balance notifications will be sent to users
  // create a new object when saving: setTimezoneSettings({...timezoneSettings})
  const [timezoneSettings, setTimezoneSettings] =
    useState<TimezoneSettings | null>(null);
  // list of numbers to notify
  // create a new list when saving: setNotifyList([...notifyList])
  const [notifyList, setNotifyList] = useState<Array<string> | null>(null);
  // custom low balance notification
  const [lowBalMsg, setLowBalMsg] = useState<string | null>(null);
  // a phone number to edit, there are two sets of phone numbers
  // a list in network settings of all the numbers for the network
  // and then this number which is being edited right at this very moment
  const [phone, setPhone] = useState<string>("");
  // show a spinner on the settings submit button while saving
  const [settingsSaving, setSettingsSaving] = useState<boolean>(false);

  // if we don't have a network value, return immediately after the state hooks are set up
  if (!props.network) {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  let fiber_mode: boolean;
  if (!fiberMode) {
    fiber_mode = false;
  } else {
    fiber_mode = fiberMode;
  }

  // There is a repeated pattern here of using the network value unless our local state has been set.
  // This prevents local values from changing once we start editing them,
  // This avoids our local changes getting overwritten by the background loop
  // This is accomplished by setting local state values to null by default, and only using them once they're not null

  // Our local settings are in $, but the backend is in wei, so we convert the values here.
  // These are then converted back to wei when sending the form in submitSettings.
  const network_settings: OperatorToolsSettingsSubmission =
    networkSettings == null
      ? EditableSettingsFromOperatorUpdate(
          props.network.settings,
          fiber_mode,
          props.network.expected_network_rates
        )
      : networkSettings;
  // if we submit this as "" then network.low_balance_msg will become null,
  // which means that we won't override the system-wide default
  const low_balance_msg =
    lowBalMsg == null ? props.network.low_balance_msg : lowBalMsg;
  const timezone_settings: TimezoneSettings =
    timezoneSettings == null ? props.network.timezone : timezoneSettings;
  const notify_types =
    notificationTypes == null ? props.network.notify_on : notificationTypes;
  const notify_list =
    notifyList == null ? props.network.notify_list : notifyList;

  // convenience method for toggling an individual notify_types button state
  function toggleNotifyType(value: string) {
    // create a new list (to trigger a render)
    let list = [...notify_types];
    if (list.includes(value)) {
      list = remove_from_array(list, value);
    } else {
      list.push(value);
    }
    setNotificationTypes(list);
  }

  let shaper_settings: ShaperSettings;
  if (!shaperSettings) {
    shaper_settings = {
      enabled: true,
      max_speed: 10000,
      min_speed: 50,
    };
  } else {
    shaper_settings = shaperSettings;
  }

  const shaper_buttons = (
    <MDBox ml={2} alignItems="flex-start" display="flex">
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <MDButton
            color={
              shaper_settings.enabled && !fiber_mode ? "success" : "secondary"
            }
            onClick={() => {
              shaper_settings.enabled = true;
              fiber_mode = false;
              setShaperSettings({ ...shaper_settings });
              setFiberMode(false);
              setShaperSettingsChanged(true);
            }}
          >
            Shaper Enabled
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton
            color={
              !shaper_settings.enabled && !fiber_mode ? "success" : "secondary"
            }
            onClick={() => {
              shaper_settings.enabled = false;
              fiber_mode = false;
              setShaperSettings({ ...shaper_settings });
              setFiberMode(false);
              setShaperSettingsChanged(true);
            }}
          >
            Shaper Disabled
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton
            color={fiber_mode ? "success" : "secondary"}
            onClick={() => {
              fiber_mode = !fiber_mode;
              setShaperSettings({ ...shaper_settings });
              setFiberMode(fiber_mode);
              setShaperSettingsChanged(true);
            }}
          >
            Fiber Mode
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );

  const hourOptions = [
    { value: 0, text: "12 AM- 00" },
    { value: 1, text: "1 AM - 01" },
    { value: 2, text: "2 AM - 02" },
    { value: 3, text: "3 AM - 03" },
    { value: 4, text: "4 AM - 04" },
    { value: 5, text: "5 AM - 05" },
    { value: 6, text: "6 AM - 06" },
    { value: 7, text: "7 AM - 07" },
    { value: 8, text: "8 AM - 08" },
    { value: 9, text: "9 AM - 09" },
    { value: 10, text: "10 AM - 10" },
    { value: 11, text: "11 AM - 11" },
    { value: 12, text: "12 PM - 12" },
    { value: 13, text: "1 PM - 13" },
    { value: 14, text: "2 PM - 14" },
    { value: 15, text: "3 PM - 15" },
    { value: 16, text: "4 PM - 16" },
    { value: 17, text: "5 PM - 17" },
    { value: 18, text: "6 PM - 18" },
    { value: 19, text: "7 PM - 19" },
    { value: 20, text: "8 PM - 20" },
    { value: 21, text: "9 PM - 21" },
    { value: 22, text: "10 PM - 22" },
    { value: 23, text: "11 PM - 23" },
  ];

  function add_phone(phone: string) {
    if (!notify_list.includes(phone)) {
      notify_list.push(phone);
      setPhone("");
      setNotifyList([...notify_list]);
    }
  }

  function remove_phone(phone: string) {
    setNotifyList(remove_from_array(notify_list, phone));
  }

  async function submitSettings() {
    setSettingsSaving(true);
    const postJson: any = {};
    const settings = network_settings;
    if (settings != null) {
      // convert back to backend units
      settings.relay = Math.round(
        dollars_per_gb_to_wei_per_byte(settings.relay)
      );
      settings.phone_relay = Math.round(
        dollars_per_gb_to_wei_per_byte(settings.phone_relay)
      );
      settings.gateway = Math.round(
        dollars_per_gb_to_wei_per_byte(settings.gateway)
      );
      settings.max = Math.round(dollars_per_gb_to_wei_per_byte(settings.max));
      settings.operator_fee = Math.round(
        dollars_per_month_to_wei_per_second(settings.operator_fee)
      );
      // shaper settings is checked for here so defaults are not overwritten in the geteditable function
      settings.warning = Math.round(eth_to_wei(settings.warning));
      if (shaperSettingsChanged) {
        settings.shaper_settings = shaper_settings;
        settings.fiber_mode = fiber_mode;
      }
    }

    postJson["address"] = props.address;
    postJson["settings"] = settings;
    postJson["notify_list"] = notify_list;
    postJson["timezone_setting"] = timezone_settings;
    postJson["low_balance_msg"] = low_balance_msg;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "network_settings", requestOptions);

    if (notify_types) {
      const postJson: any = {};
      postJson["address"] = props.address;
      postJson["types"] = notify_types;
      const requestOptions: any = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(postJson),
      };
      await fetch(SERVER_URL + "alerts_set", requestOptions);
    }
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    setNetworkSettings(null);
    setShaperSettingsChanged(false);
    setShaperSettings(settings.shaper_settings);
    setFiberMode(settings.fiber_mode);
    setNotificationTypes(null);
    setTimezoneSettings(null);
    setLowBalMsg(null);
    setPhone("");
    await props.get_network();
    setSettingsSaving(false);

    return res;
  }

  return (
    <Container>
      <h2>Pricing</h2>
      <hr />
      <Form>
        <FormGroup>
          <Label for="relay_bandwidth_price">
            <h5>Relay price</h5>
          </Label>
          <p>
            The price that relays will be paid for forwarding bandwidth, this
            can be overridden by the client unless you select the option to
            prevent that. (option coming soon)
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.relay}
              name="relay_price"
              id="relay_bandwidth_price"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.relay = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="phone_relay_bandwidth_price">
            <h5>Phone Relay price</h5>
          </Label>
          <p>
            The price that phone relays will be paid for forwarding bandwidth.
            There is currently no way to set this on the client
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.phone_relay}
              name="phone_relay_price"
              id="phone_relay_bandwidth_price"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.phone_relay = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="gateway_bandwidth_price">
            <h5>Gateway price</h5>
          </Label>
          <p>
            The gateway bandwidth price is the default price for any node in the
            network that has a WAN port on it's port toggling page this is not
            prone to false negatives, but can be prone to false positives. Like
            the relay price this can be overridden locally.
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.gateway}
              name="gateway_price"
              id="gateway_bandwidth_price"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.gateway = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="max_bandwidth_price">
            <h5>Maximum bandwidth price</h5>
          </Label>
          <p>
            The maximum bandwidth price that a node will pay. If a bandwidth
            price about this value is set the router will only pay this value.
            Take care in networks with deep relay links that this value is
            greater than your exit price + gateway price + (relay price * relay
            hops) or nodes that deep will not pay enough and will constantly be
            enforced upon.
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              value={network_settings.max}
              type="number"
              step="0.001"
              name="max_price"
              id="max_bandwidth_price"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.max = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="operator_fee">
            <h5>Operator fee</h5>
          </Label>
          <p>
            The operator fee paid to your operator addres, denominated in
            dollars per month note that this is pro-rated and actually paid by
            the second. So routers with low balances will stop paying this
            amount until new funds are deposited.
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              value={to_fixed_number(network_settings.operator_fee)}
              type="number"
              step="1"
              name="operator_fee"
              id="operator_fee"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.operator_fee = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="low_balance">
            <h5>Low balance warning</h5>
          </Label>
          <p>
            Once the balance on a router is smaller than this amount the user
            will see a red warning bar on their dashboard and be sent a text
            message prompting them to add funds
          </p>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <MDInput
              value={to_fixed_number(network_settings.warning)}
              type="number"
              step="1"
              name="low_balance"
              id="low_balance"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.warning = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <hr />
        <h2>Speed and Latency</h2>
        <hr />
        <p>
          These settings are displayed to customers as the expected
          upload/download speed and latency for an average user on the network.
        </p>
        <FormGroup>
          <Label for="upload">
            <h5>Expected Upload Speed</h5>
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Mbps</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.expected_upload_speed}
              name="upload"
              id="upload"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.expected_upload_speed = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="download">
            <h5>Expected Download Speed</h5>
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Mbps</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.expected_download_speed}
              name="download"
              id="download"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.expected_download_speed = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="latency">
            <h5>Average Latency</h5>
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>ms</InputGroupText>
            </InputGroupAddon>
            <MDInput
              type="number"
              step="0.001"
              value={network_settings.expected_latency}
              name="latency"
              id="latency"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                network_settings.expected_latency = Number(e.target.value);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>

        <hr />
        <h2>Network</h2>
        <hr />
        <FormGroup>
          <h5>Bandwidth Shaper Enabled and Fiber only Mode</h5>
          <p>
            The bandwidth shaper automatically tunes the throughput of links in
            order to keep them stable and prevent overloading of the radios.
            This is one of the key Althea features that allows for a great
            customer experience even when things are overloaded. It will also
            keep links with signal issues stable enough to use, although it can
            not make them any faster. You should only disable this if you're
            experiencing strange issues with new models of point to point radios
            otherwise it's probably the link itself that needs attention. Fiber
            Mode is used for fiber networks only and disables the shaper while
            stabilizing babel latency computation by keeping links from
            overloading.
          </p>

          {shaper_buttons}
        </FormGroup>
        <FormGroup>
          <Label for="shaper_minimum">
            <h5>Bandwidth Shaper Minimum</h5>
          </Label>
          <p>
            the minimum value is the slowest the shaper will ever make a link.
            It should be set to the speed capability of the worst link in your
            network
          </p>
          <InputGroup>
            <InputGroupAddon addonType="append">
              <InputGroupText>Mbps</InputGroupText>
            </InputGroupAddon>
            <MDInput
              value={shaper_settings.min_speed}
              type="number"
              step="1"
              name="minimum_shaper_speed"
              id="minimum_shaper_speed"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                shaper_settings.min_speed = Number(e.target.value);
                setShaperSettings({ ...shaper_settings });
                setFiberMode(fiber_mode);
                setShaperSettingsChanged(true);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="shaper_maximum">
            <h5>Bandwidth Shaper Maximum</h5>
          </Label>
          <p>
            This value should be at or above the ideal speed of the fastest
            connection in your network
          </p>
          <InputGroup>
            <InputGroupAddon addonType="append">
              <InputGroupText>Mbps</InputGroupText>
            </InputGroupAddon>
            <MDInput
              value={shaper_settings.max_speed}
              type="number"
              step="1"
              name="max_shaper_speed"
              id="max_shaper_speed"
              placeholder=""
              onChange={(e: { target: { value: any } }) => {
                shaper_settings.max_speed = Number(e.target.value);
                setShaperSettings({ ...shaper_settings });
                setShaperSettingsChanged(true);
                setNetworkSettings({ ...network_settings });
              }}
            />
          </InputGroup>
        </FormGroup>
        <hr />
        <h2>Notification</h2>
        <hr />
        <FormGroup>
          <Label for="notifications_enabled">
            <h5>Enabled notifications</h5>
          </Label>
          <p>
            These are events you can choose to be notified on. You will always
            get all types of notifications on your dashboard. But the ones
            selected here will generate a text message to configured phones.
            Green = you will be texted Red = you will not be texted
          </p>
          <li>
            <b>Info</b>: can be a variety of minor things, this node needs an
            update, this node is switching between upstreams very often, this
            node is running more slowly than it should
          </li>
          <li>
            <b>Balance</b>: Get a notification when a user in your network has a
            low balance
          </li>
          <li>
            <b>Connection Instability</b>: This alert is a step below downtime,
            it's when the connection data we're getting for a user is less than
            ideal but the user still has internet access
          </li>
          <li>
            <b>Minor Downtime</b>: This alert means a regular client is down
          </li>
          <li>
            <b>Major Downtime</b>: This alert means a relay or gateway in your
            network is down, any time this is triggered it will trigger some
            Minor Downtime notifications as well.
          </li>
          <br></br>
          <ButtonGroup>
            <MDButton
              color={notify_types.includes("Info") ? "success" : "error"}
              onClick={() => toggleNotifyType("Info")}
            >
              Info
            </MDButton>
            <MDButton
              color={notify_types.includes("Balance") ? "success" : "error"}
              onClick={() => toggleNotifyType("Balance")}
            >
              Balance
            </MDButton>
            <MDButton
              color={
                notify_types.includes("ConnectionInstability")
                  ? "success"
                  : "error"
              }
              onClick={() => toggleNotifyType("ConnectionInstability")}
            >
              Connection Instability
            </MDButton>
            <MDButton
              color={
                notify_types.includes("MinorDowntime") ? "success" : "error"
              }
              onClick={() => toggleNotifyType("MinorDowntime")}
            >
              Minor Downtime
            </MDButton>
            <MDButton
              color={
                notify_types.includes("MajorDowntime") ? "success" : "error"
              }
              onClick={() => toggleNotifyType("MajorDowntime")}
            >
              Major Downtime
            </MDButton>
          </ButtonGroup>
        </FormGroup>
        <hr />
        <FormGroup>
          <Label for="time_set">
            <h5>Notification times for Users</h5>
          </Label>
          <p>
            Enter timezone by UTC Offset and hours within which low balance text
            notifications may be sent
          </p>
          <p>
            Offset from UTC
            <br />
            <MDInput
              type="select"
              name="time_set"
              id="time_set"
              onChange={(e: { target: { value: any } }) => {
                timezone_settings.timezone_offset = Number(e.target.value);
                setTimezoneSettings({ ...timezone_settings });
              }}
              value={timezone_settings.timezone_offset}
            >
              <option value="0">0 - UTC</option>
              <option value="-1">-1 - CVT</option>
              <option value="-2">-2 - VTZ</option>
              <option value="-3">-3 - BST</option>
              <option value="-4">-4 - AST</option>
              <option value="-5">-5 - EST (USA)</option>
              <option value="-6">-6 - CST (USA)</option>
              <option value="-7">-7 - MST (USA)</option>
              <option value="-8">-8 - PST (USA)</option>
              <option value="-9">-9 - AKST (USA)</option>
              <option value="-10">-10 - HST (USA)</option>
              <option value="-11">-11 - SST</option>
              <option value="-12">-12 - IDLW</option>
              <option value="1">+1 - CET</option>
              <option value="2">+2 - EET</option>
              <option value="3">+3 - MSK</option>
              <option value="4">+4 - GST</option>
              <option value="5">+5 - PKT</option>
              <option value="6">+6 - BDT</option>
              <option value="7">+7 - JT</option>
              <option value="8">+8 - CCT</option>
              <option value="9">+9 - JST</option>
              <option value="10">+10 - GST</option>
              <option value="11">+11 - SBT</option>
              <option value="12">+12 - NZST</option>
            </MDInput>
          </p>
          <p>
            Start and End times for notifications within a 24h period (starting
            at midnight). To enable 24h notifications, leave the start and end
            times the same. Otherwise, start time must be before end time or the
            time update will not be applied.
          </p>
          <p>
            Select start time in local time during which low balance text
            notifications may be sent
            <br />
            <MDInput
              type="select"
              onChange={(e: { target: { value: any } }) => {
                timezone_settings.start_notifs = Number(e.target.value);
                setTimezoneSettings({ ...timezone_settings });
              }}
              value={timezone_settings.start_notifs}
            >
              {hourOptions.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </MDInput>
          </p>
          <p>
            Select end time (hour only, 24-hour format) in local time during
            which low balance text notifications may be sent
            <br />
            <MDInput
              type="select"
              onChange={(e: { target: { value: any } }) => {
                timezone_settings.end_notifs = Number(e.target.value);
                setTimezoneSettings({ ...timezone_settings });
              }}
              value={timezone_settings.end_notifs}
            >
              {hourOptions.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </MDInput>
          </p>
        </FormGroup>
        <hr />
        <FormGroup>
          <Label for="notify_number">
            <h5>Notification numbers</h5>
          </Label>
          <p>
            Phones on this list will receive a text message during specific
            downtime conditions
          </p>
          <PhoneList notify_list={notify_list} remove={remove_phone} />
          <p></p>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US"
            value={phone}
            onChange={(e) => setPhone(String(e))}
            inputComponent={InnerPhoneInput}
          />
          <p></p>
          <MDButton
            disabled={!isValidPhoneNumber(phone)}
            onClick={() => add_phone(phone)}
          >
            Add
          </MDButton>
        </FormGroup>
        <hr />
        <FormGroup>
          <Label for="low_balance_msg">
            <h5>Custom low balance message</h5>
          </Label>
          <p>
            If set, this text will replace the default message sent to a user
            when a router balance is low. The following variables can be used
            and will be replaced with router values: [nickname], [balance]
          </p>
          <MDInput
            type="text"
            name="low_balance_msg"
            id="low_balance_msg"
            fullWidth
            placeholder=""
            value={low_balance_msg || ""}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => {
              // set low-balance-msg, "" clears it so the default message will be used instead
              setLowBalMsg(e.target.value);
            }}
          />
        </FormGroup>
      </Form>
      <hr />
      <p></p>
      <MDButton onClick={() => submitSettings()} disabled={settingsSaving}>
        {settingsSaving ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Saving ...
          </>
        ) : (
          <> Save All Settings </>
        )}
      </MDButton>
      <hr />
      <h2>Data Export</h2>
      <hr />
      <p>
        Exports this networks Router data to a CSV file, does not edit any other
        settings
      </p>
      <InputGroupAddon addonType="append">
        <MDButton
          onClick={() =>
            window.open(SERVER_URL + "networkcsv/" + props.address, "_blank")
          }
        >
          Download CSV
        </MDButton>
      </InputGroupAddon>
      <p></p>
    </Container>
  );
};

export default Settings;
