/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.png";

import { Link, useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";

interface LoginPageProps {
  login: Function;
  lastLoginFailed: boolean;
}

const SignIn: React.FC<LoginPageProps> = (props) => {
  const [username, setUsername] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [errorText, setErrorText] = useState<string>("");
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const navigate = useNavigate();

  const renderErrorSB = (content: string) => (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={content}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const submitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const res = await props.login(username, password);
    if (res !== null && res.ok) {
      navigate("/");
    } else {
      if (!res) {
        setErrorText(JSON.stringify(res));
      } else {
        setErrorText(
          "Incorrect username or password!"
        );
      }
      openErrorSB();
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {renderErrorSB(errorText)}
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Log In to Operator Tools
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                value={username}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUsername(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                value={password}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                component={Link}
                variant="gradient"
                color="info"
                fullWidth
                disabled={!username || !password}
                onClick={submitHandler}
              >
                Login
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Need an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/signup/verify"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default SignIn;